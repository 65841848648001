(function (angular, app) {
	'use strict';

	app.service('RU', [
		function () {
			var self = this;

			self.id = 4;
			self.culture = 'ru';
			self.title = 'русский';
			self.label = 'Russian';
			self.short = 'рус';
			self.direction = 'ltr';
			self.icon = 'https://d226b0iufwcjmj.cloudfront.net/localization/ru.png';
			self.dateFormat = {
				date: 'dd/MM/yyyy',
				time: 'hh:mm a',
				dateAndTime: 'dd/MM/yyyy' + ' ' + 'hh:mm a'
			};
			self.translate = {

				'arabic': 'Арабский',
				'english': 'Английский',
				'spanish': 'Испанский',
				'french': 'Французский',
				'hebrew': 'Иврит',
				'russian': 'Русский',

				// 'en': 'he',
				'left': 'Влево',
				'right': 'Вправо',
				'up': 'Вверх',
				'down': 'Вниз',
				'search': 'Поиск',
				'add to cart': 'Добавить',
				'add to current cart': 'Добавить в данную корзину',
				'create new order': 'Создать новый заказ',
				'cannot add to cart': 'невозможно добавить в корзину',
				'add cart products': 'Добавьте продукты из корзины',
				'special products': 'Акционные товары',
				'save to shopping list': 'Сохранить в список покупок',
				'new list name': 'Новый список',
				'home': 'Дом',
				'powered by': 'Основано на технологии',
				'remove': 'Удалить',
				'login': 'Войти',
				'email': 'Электронная почта',
				'password': 'Пароль',
				'logout': 'Выйти',
				'orders history': 'История заказов',
				'recent orders': 'Последние заказы',
				'checkout': 'Завершить покупку',
				'clear cart': 'Очистить всё',
				'callcustomer': 'Позвонить мне',
				'creditcard': 'Кредитная карта',
				'storecredit': 'Магазинный кредит',
				'the delivery time is no longer available': 'Время доставки больше не доступно',
				'add card': 'Добавить карту',
				'activate card': 'Активировать корзину',
				'add another card': 'Добавить дополнительную карту',
				'order update': 'Обновление заказа',
				'updated order value': 'Обновленная стоимость заказа',
				'(estimated)': '(приблизительно)',
				'in order to complete the order update, please choose the payment method': 'Чтобы завершить обновление заказа, выберите способ оплаты',
				'confirm update': 'ПОДТВЕРДИТЬ ОБНОВЛЕНИЕ',
				'add another account': 'Добавить аккаунт',
				'remove card': 'Удалить карту',
				'pay with': 'Оплатить с помощью',
				'remove account': 'Удалить аккаунт',
				'credit card number': 'Номер кредитной карты',
				'cvv': 'cvv',
				'delivery address': 'Адрес доставки',
				'city': 'Город',
				'street': 'Улица',
				'phone num': 'Номер телефона',
				'recipient\'s name': 'Имя получателя',
				'floor / apartment': 'Квартира / Этаж',
				'house number': '№ дома',
				'house no.': '№ дома',
				//'entry: spautocompleterequirematch error': 'Подъезд (а-к)',
				'house_entry_none': 'без',
				// 'house_entry_a': 'а',
				// 'house_entry_b': 'b',
				// 'house_entry_c': 'c',
				// 'house_entry_d': 'd',
				// 'house_entry_e': 'e',
				// 'house_entry_f': 'f',
				// 'house_entry_g': 'g',
				// 'house_entry_h': 'h',
				// 'house_entry_i': 'i',
				// 'house_entry_j': 'j',
				'delivery time': 'Время доставки',
				'pickup time': 'Время самовывоза',
				'today': 'Сегодня',
				'sunday': 'Вск.',
				'monday': 'Пон.',
				'tuesday': 'Втр.',
				'wednesday': 'Срд.',
				'thursday': 'Чтв.',
				'friday': 'Птн.',
				'saturday': 'Субб.',
				'sun': 'вск.',
				'mon': 'пон.',
				'tue': 'втр.',
				'wed': 'срд.',
				'thu': 'чтв.',
				'fri': 'птн.',
				'sat': 'субб.',
				'save_1': 'сэкономь',
				'notes': 'Примечания',
				'add instructions': 'Добавьте указания',
				'summary': 'Итог',
				'products': 'Товары',
				'you saved': 'Вы сэкономили',
				'payment': 'Оплата',
				'delivery charge': 'Стоимость доставки',
				'price without discount': 'Цена без скидки',
				'price after discount': 'Цена со скидкой',
				'total': 'Итого',
				'between': 'между',
				'order id': 'Номер заказа',
				'your order has been received': 'Ваш заказ принят',
				'shopping lists': 'Перечень покупок',
				'shopping list created': 'Перечень покупок создан',
				'forgot password': 'Забыли пароль',
				'register': 'Зарегистрироваться',
				'retailer details': 'Реквизиты магазина',
				'in all departments': 'Во всех отделах',
				'edit details': 'Редактировать данные',
				'loading': 'Загружается',
				'supervision': 'Кошерность',
				'load previous orders': 'Загрузить предыдущие заказы',
				'close previous orders': 'Закрыть предыдущие заказы',
				'previous orders': 'Предыдущие заказы',
				'view details' : 'Посмотреть детали',
				'payment method': 'Способ оплаты',
				'number of provided products' : 'Количество предоставленных товаров',
				'substitutions': 'Замены',
				'order time' :'Время заказа',
				'total (estimated)' : 'Итого (примерно)',
				'call customer' : 'Позвонить клиенту',
				'payment method ending with': 'Способ оплаты заканчивается на',
				'credit card charge' : 'Оплата кредитной картой',
				'replaced with': 'Заменено на',
				'new product' : 'Новый продукт',
				'ordered' : 'Заказано',
				'received_items' : 'Получено',
				'sale': 'Акция',
				'club sale': 'Акция клуба',
				'choose brand': 'Выбрать бренд',
				'specials_and_benefits_title': 'Акции',
				'specials': 'Акции',
				'item': 'Товар',
				'items': 'Товары',
				'hide cart': 'Скрыть корзину',
				'best ingredients for best results': 'Лучшие ингредиенты для лучших результатов',
				'order items by': 'Сортировать товары по',
				'name': 'Наименование',
				'actions': 'Действия',
				'creation date': 'Дата создания',
				'delete': 'Удалить',
				'create new shopping list': 'Создать новый список покупок',
				'shopping list name': 'Наименование списка покупок',
				'create': 'Создать',
				'id': 'Номер',
				'time': 'Время',
				'date and time': 'Дата и время',
				'ok': 'Разрешить',
				'address': 'Адрес',
				'phone': 'Телефон',
        'amount exceeded eligibility': 'Превышена допустимая сумма',
				'the site is undergoing maintenance': 'Сайт на техобслуживании',
				'please try again later': 'Пожалуйста, повторите попытку позже',
				'the site is active only for registered users, please login': 'Сайт активен только для зарегистрированных пользователей, пожалуйста, войдите в систему',
				'the site is disabled': 'Сайт недоступен',
				'comment': 'Комментарий',
				'noun search': 'Поиск',
				'finish order': 'Завершить заказ',
				'reset password': 'Переустановить пароль',
				'mobile': 'Моб.',
				'mobile phone': 'Мобильный телефон',
				'phone number': 'Номер телефона',
				'edit': 'Редактировать',
				'add phone': 'Добавить телефон',
				'first name': 'Имя',
				'last name': 'Фамилия',
				'birth date': 'Дата рождения',
				'user name and password': 'Имя пользователя и пароль',
				'user name': 'Имя пользователя',
				'gender': 'Пол',
				'male': 'Мужской',
				'female': 'Женский',
				'prefer not to say': 'Предпочитаю не говорить',
				'personal information': 'Личные данные',
				'user details': 'Данные пользователя',
				'contact details': 'Контактные данные',
				'comments': 'Комментарии',
				'work': 'Раб.',
				'allow to send promotions': 'Согласен/а на получение рекламных рассылок',
				'my items': 'Мои товары',
				'image': 'Фото',
				'price': 'Цена',
				'quantity': 'Количество',
				'actions on marked items': 'Действия по отмеченным товарам',
				'create new cart': 'Создать новую корзину',
				'create shopping list': 'Создать список покупок',
				'no products were selected': 'Товары не выбранны',
				'total sum': 'Общая сумма',
				'order status': 'Статус заказа',
				'new': 'Получен',
				'received': 'Получен',
				'charged': 'Оплачен',
				'delivered': 'Доставлен',
				'cancelled': 'Отменён',
				'suspended': 'Приостановлен',
				'processing': 'В обработке',
				'waiting_for_confirmation': 'В обработке',
				'user_confirmed': 'В обработке',
				'collected': 'Собран',
				'registered': 'Зарегестрирован',
				'received text': 'Заказ успешно принят, но оплата еще не произведена',
				'charged text': 'Сумма покупки снята с кредитной карты',
				'delivered text': 'Заказ отправлен и находится по пути к вам',
				'cancelled text': 'Заказ отменен и не обрабатывается',
				'suspended text': 'Невозможно завершить заказ, требуется вмешательство клиента',
				'processing text': 'Заказ на данный момент комплектуется в магазине',
				'collected text': 'Товары укомплектованны, но пока не проведенны по кассе',
				'registered text': 'Товары укомплектованны и проведенны по кассе, но деньги с карты еще не списаны',
				'no products were found': 'Товары не найденны',
				'order number': 'Номер заказа',
				'date': 'Дата',
				'back': 'Вернуться',
				'save': 'Сохранить',
				'shopping list': 'Список покупок',
				'address 1': 'Улица + номер дома',
				'address 2': 'Подъезд, этаж + квартира',
				'i confirm that i have read the ': 'Я подтверждаю, что прочитал\\а ',
				'terms': 'Условия',
				'similar products': 'Похожие товары',
				'check all': 'Отметить все',
				'uncheck all': 'Отменить всё',
				'all': 'Все',
				'narrow your choice': 'Фильтр товаров',
				'brand': 'Бренд',
				'type': 'Тип',
				'category': 'Категория',
				'sign in': 'Войти',
				'sign in using facebook': 'Войти через facebook',
				'it\'s easy': 'это легко',
				'please sign in to continue...': 'Пожалуйста, зайдите на сайт, чтобы продолжить...',
				'country': 'Страна',
				'at': 'в',
				'let\'s start': 'Приступить',
				'adding some items': 'Добавить товары',
				'it\'s easy!': 'Это легко!',
				'forgot password?': 'Забыли пароль?',
				'confirm password': 'Подтвердить пароль',
				'i agree to the': 'Я подтверждаю, что прочитал\\а',
				'terms & conditions': 'Условиями и Правилами',
				'policy_end': 'и согласен/а с их условиями',
				'please let me know about promotions': 'Пожалуйста, оповещайте меня о рекламных акциях',
				'reset': 'Восстановить',
				'please check your email for the password reset information': 'Пожалуйста, проверьте свою электронную почту с целью переустановки пароля',
				'please check your inbox and follow the instructions': 'Посмотрите входящие сообщения и следуйте инструкциям',
				'if you haven\'t received an email, please check if you mistyped your email address or contact customer service': 'Если вы не получили эл. письмо, проверьте правильность написания адреса эл. почты или обратитесь в службу поддержки клиентов',
				'open previous carts:': 'Открыть предыдущие покупки:',
				'save and sign in': 'Сохранить и войти',
				'save for later': 'Сохранить на потом',
				'today\'s special deals': 'ТОП предложения',
				'no': 'Нет',
				'yes': 'Да',
				'delivery': 'Доставка',
				'pickup': 'Самовывоз',
				'pick and go': 'Выбрать и оплатить',
				'pick & go': 'Выбрать и оплатить',
				'undefined': 'Не определен',
				'anything else': 'Что-нибудь еще',
				'delivery date': 'Дата доставки',
				'pickup date': 'Дата самовывоза',
				'estimated total': 'Сумма (примерно)',
				'estimated': 'примерно',
				'allow substitutions': 'Разрешить замену',
				'delivery date and time': 'Дата и время доставки',
				'next': 'Далее',
				'contact details & payment': 'Контактные данные и оплата',
				'checkout contact details': 'Контактные данные',
				'summary & payment': 'Итог и оплата',
				'delivery/pickup preferences': 'Предпочтения по доставке\\самовывозу',
				'how do you want to get your order?': 'Как вы предпочитаете получить свой заказ ?',
				'the site is active only for registered users': 'Сайт активен только для зарегистрированных пользователей',
				'please login': 'Пожалуйста, войдите',
				'sort by': 'Сортировать по',
				'save as a shopping list': 'Сохранить в список покупок',
				'your shopping cart': 'Ваша корзина',
				'save to list': 'Сохранить в список',
				'product name': 'Наименование товара',
				'order ': 'Заказ',
				'order': 'Порядок',
				'unit price': 'Цена за единицу',
				'units': 'Количество',
				'unit': 'ед',
				'proceed to checkout': 'Завершить покупку',
				'we couldn\'t find any products': 'Товары не найдены',
				'est.': 'Примерно',
				'est. total weight': 'Примерный общий вес',
				'est. weight': 'Примерный вес',
				'mobile: required error': 'Введите номер мобильного телефона',
				'mobilephone: required error': 'Введите номер мобильного телефона',
				'phone: required error': 'Введите номер телефона',
				'phone: minlength error': 'Номер телефона должен содержать минимум 7 цифр',
				'city: required error': 'Введите название города',
				'city: spautocompleterequirematch error': 'Город не найден',
				'cityinput: spautocompleterequirematch error': 'Город не найден',
				'street: required error': 'Введите название улицы',
				'street: spautocompleterequirematch error': 'Улица не найдена',
				'streetinput: spautocompleterequirematch error': 'Улица не найдена',
				'housenum: required error': 'Введите номер дома',
				'housenum: min error': 'Введите номер дома',
				'text1: required error': 'Введите адрес',
				'addresstext: required error': 'Введите адрес',
				'fullname: required error': 'Введите полное имя',
				'email: required error': 'Введите адрес электронной почты',
				'email: email error': 'Введите правильный адрес электронной почты',
				'firstname: required error': 'Имя',
				'lastname: required error': 'Фамилия',
				'password: required error': 'Введите пароль',
				'password: minlength error': 'Пароль должен состоять минимум из 6 символов',
				'password: maxlength error': 'Пароль должен содержать не более 30 символов',
				'password: pattern error': 'Пароль должен содержать хотя бы одну цифру и одну букву',
				'confirmpassword: required error': 'Подтвердить пароль',
				'confirm_password: required error': 'Подтвердить пароль',
                'policyapproval: required error': 'Подтвердить согласие с правилами и условиями сайта',
                'please confirm that you accept the terms and conditions for using this site': 'Убедитесь, что вы согласны с условиями использования этого сайта',
				'zipcode: required error': 'Введите индекс',
				'message: required error': 'Сообщение',
				'loyaltycardid: required error': 'Введите номер карты члена клуба',
				'ValidationKey: required error': 'Введите номер удостоверения личности',
				'birthdate: date error': 'Дата рождения',
				'confirmpassword: passwords do not match error': 'Пароли не совпадают',
				'confirm_password: Passwords do not match error': 'Пароли не совпадают',
				'confirmPassword: pattern error': 'Пароли не совпадают',
				'confirm_password: pattern error': 'Пароли не совпадают',
				'floor: step error': 'Этаж',
				'floor: not_editable error': 'Вы не можете вносить изменения в это поле, пока не введете почтовый индекс для своего адреса.',
				'floor: not_editable_locked error': 'Для того чтобы изменить адрес, выберите из списка почтовый индекс.',
				'floor: not_editable_locked_empty error': 'Введите свой почтовый индекс и выберите адрес из списка.',
				'text1: not_editable error': 'Вы не можете вносить изменения в это поле, пока не введете почтовый индекс для своего адреса.',
				'text1: not_editable_locked error': 'Для того чтобы изменить адрес, выберите из списка почтовый индекс.',
				'text1: not_editable_locked_empty error': 'Введите свой почтовый индекс и выберите адрес из списка.',
				'text2: not_editable error': 'Вы не можете вносить изменения в это поле, пока не введете почтовый индекс для своего адреса.',
				'text2: not_editable_locked error': 'Для того чтобы изменить адрес, выберите из списка почтовый индекс.',
				'text2: not_editable_locked_empty error': 'Введите свой почтовый индекс и выберите адрес из списка.',
				'street: not_editable error': 'Вы не можете вносить изменения в это поле, пока не введете почтовый индекс для своего адреса.',
				'street: not_editable_locked error': 'Для того чтобы изменить адрес, выберите из списка почтовый индекс.',
				'street: not_editable_locked_empty error': 'Введите свой почтовый индекс и выберите адрес из списка.',
				'cityinput: not_editable error': 'Вы не можете вносить изменения в это поле, пока не введете почтовый индекс для своего адреса.',
				'cityinput: not_editable_locked error': 'Для того чтобы изменить адрес, выберите из списка почтовый индекс.',
				'cityinput: not_editable_locked_empty error': 'Введите свой почтовый индекс и выберите адрес из списка.',
				'city: not_editable error': 'Вы не можете вносить изменения в это поле, пока не введете почтовый индекс для своего адреса.',
				'city: not_editable_locked error': 'Для того чтобы изменить адрес, выберите из списка почтовый индекс.',
				'city: not_editable_locked_empty error': 'Введите свой почтовый индекс и выберите адрес из списка.',
				'countryinput: not_editable error': 'Вы не можете вносить изменения в это поле, пока не введете почтовый индекс для своего адреса.',
				'countryinput: not_editable_locked error': 'Для того чтобы изменить адрес, выберите из списка почтовый индекс.',
				'countryinput: not_editable_locked_empty error': 'Введите свой почтовый индекс и выберите адрес из списка.',
				'housenum: not_editable error': 'Вы не можете вносить изменения в это поле, пока не введете почтовый индекс для своего адреса.',
				'housenum: not_editable_locked error': 'Для того чтобы изменить адрес, выберите из списка почтовый индекс.',
				'housenum: not_editable_locked_empty error': 'Введите свой почтовый индекс и выберите адрес из списка.',
				'entry: not_editable error': 'Вы не можете вносить изменения в это поле, пока не введете почтовый индекс для своего адреса.',
				'entry: not_editable_locked error': 'Для того чтобы изменить адрес, выберите из списка почтовый индекс.',
				'entry: not_editable_locked_empty error': 'Введите свой почтовый индекс и выберите адрес из списка.',
				'entrance: not_editable error': 'Вы не можете вносить изменения в это поле, пока не введете почтовый индекс для своего адреса.',
				'entrance: not_editable_locked error': 'Для того чтобы изменить адрес, выберите из списка почтовый индекс.',
				'entrance: not_editable_locked_empty error': 'Введите свой почтовый индекс и выберите адрес из списка.',
				'apartment: not_editable error': 'Вы не можете вносить изменения в это поле, пока не введете почтовый индекс для своего адреса.',
				'apartment: not_editable_locked error': 'Для того чтобы изменить адрес, выберите из списка почтовый индекс.',
				'apartment: not_editable_locked_empty error': 'Введите свой почтовый индекс и выберите адрес из списка.',
				'apartmentinput: not_editable error': 'Вы не можете вносить изменения в это поле, пока не введете почтовый индекс для своего адреса.',
				'apartmentinput: not_editable_locked error': 'Для того чтобы изменить адрес, выберите из списка почтовый индекс.',
				'apartmentinput: not_editable_locked_empty error': 'Введите свой почтовый индекс и выберите адрес из списка.',
				'address1input: not_editable error': 'Вы не можете вносить изменения в это поле, пока не введете почтовый индекс для своего адреса.',
				'address1input: not_editable_locked error': 'Для того чтобы изменить адрес, выберите из списка почтовый индекс.',
				'address1input: not_editable_locked_empty error': 'Введите свой почтовый индекс и выберите адрес из списка.',
				'address2input: not_editable error': 'Вы не можете вносить изменения в это поле, пока не введете почтовый индекс для своего адреса.',
				'address2input: not_editable_locked error': 'Для того чтобы изменить адрес, выберите из списка почтовый индекс.',
				'address2input: not_editable_locked_empty error': 'Введите свой почтовый индекс и выберите адрес из списка.',
				'comments: not_editable error': 'Вы не можете вносить изменения в это поле, пока не введете почтовый индекс для своего адреса.',
				'comments: not_editable_locked error': 'Для того чтобы изменить адрес, выберите из списка почтовый индекс.',
				'comments: not_editable_locked_empty error': 'Введите свой почтовый индекс и выберите адрес из списка.',
				'zipcode: not_editable error': 'Вы не можете вносить изменения в это поле',
				'zipinput: not_editable error': 'Вы не можете вносить изменения в это поле',
				'zipcodeinput: not_editable error': 'Вы не можете вносить изменения в это поле',
				'please enter an amount': 'Пожалуйста, введите количество',
				'select order': 'Выбрать порядок',
				'create new list': 'Создать новый список',
				'my account': 'Мой профиль',
				'continue shopping': 'Продолжить покупку',
				'homepagenavigation': 'На домашнюю страницу',
				'add comments': 'Добавить комментарий',
				'add comment': 'Добавить комментарий',
				'what happens if something i ordered is not available?': 'Что произойдет, если того, что я заказал, нет в наличии ?',
				'read more': 'Читать далее',
				'substitute_preference_1_label': 'Разрешаю замену',
				'substitute_preference_1_description': 'Мы заменим недостающие товары без согласования с вами',
				'substitute_preference_2_label': 'Позвонить мне',
				'substitute_preference_2_description': 'Мы произведём замену  товара, согласовав с вами по телефону',
				'substitute_preference_3_label': 'Не заменять',
				'substitute_preference_3_description': 'Мы не будем заменять недостающие товары',
				'substitute_preference_4_label': 'Отправить SMS',
				'substitute_preference_4_description': 'Перед тем как заменить недостающие товары мы отправим вам SMS',
				'substitute_preference_5_label': 'WhatsApp',
				'substitute_preference_5_description': 'Перед тем как заменить недостающие товары мы отправим вам сообщение WhatsApp',
				'substitute_preference__label': 'Предотвратить ошибку',
				'substitute_preference__description': 'Предотвратить ошибку',
				'zip code': 'Индекс',
				'zip': 'Индекс',
				'full name': 'Полное имя',
				'payment details': 'Данные об оплате',
				'your order will be delivered': 'Ваш заказ будет доставлен в',
				'your order will be ready for pickup': 'Ваш заказ будет готов к отправке',
				'substitution choice': 'Выбор замены',
				'address line 1': 'Адресная строка',
				'add another address line': 'Добавьте дополнительную адресную строку',
				'address line 2': 'Дополнительная адресная строка',
				'remove another address': 'Удалить дополнительный  адрес',
				'almost there!': 'Почти закончили...',
				'send the delivery sooner, if possible': 'Отправить доставку по возможности раньше',
				'prepare the order sooner, if possible': 'Подготовьте заказ по возможности раньше',
				'sooner if possible': 'По возможности раньше',
				'checkout summary': 'Итог покупки',
				'only': 'Только',
				'day': 'День',
				'free': 'Бесплатно',
				'total save': 'Общая экономия',
				'place order': 'Разместить заказ',
				'your email or password seems to be wrong': 'электронная почта или пароль указаны неверно',
				'please try again': 'Попробуйте еще раз',
				'oops': 'упс',
				'null': 'ноль',
				'select': 'Выбор',
				'show previous orders': 'Показать предыдущие заказы',
				'hide previous orders': 'Скрыть предыдущие заказы',
				'choose list': 'Выбрать список',
				'choose order': 'Выбрать заказ',
				'choose shopping list': 'Выбрать список покупок',
				'we couldn\'t create your shopping list': 'Нам не удалось создать список ваших покупок',
				'list name': 'Название списка',
				'create list': 'Создать список',
				// 'more details': 'Доп. информация',
				'floor': 'Этаж',
				'apartment': 'Квартира',
				'close details': 'Скрыть детали',
				'we just sent you an order confirmation by email': 'Подтверждение заказа было выслано вам на электронную почту',
				'thank you for shopping at': 'Спасибо, что купили в',
				'please add your payment card': 'Пожалуйста, добавьте данные вашей кредитной карты',
				'my orders': 'Мой заказ',
				'hours': 'Время',
				'ready for pickup': 'Готов для самовывоза',
				'we are sorry, but the following products are out of stock': 'Мы сожалеем, но данных товаров нет в наличии',
				'remove from cart & continue': 'Удалить из корзины и продолжить',
				'you save': 'Вы сэкономили',
				'can\'t find item?': 'Не нашли товар?',
				'can\'t find an item?': 'Не нашли товар?',
				'tax': 'Налог',
				'unknown': 'Неизвестный',
				'other': 'Другой',
				'add it here': 'Добавьте это здесь',
				'choose category': 'Выбрать категорию',
				'if we are unfamiliar with the product or don\'t have it in stock, we will give you a call': 'Если нужного товара нет в наличии, с вами свяжутся',
				'store info': 'Информация о магазине',
				'stores info': 'Информация о магазинах',
				'store-details': 'Данные магазина',
				'store location': 'Местонахождение магазина',
				'price high-low': 'Цена от высокой к низкой',
				'price low-high': 'Цена от низкой к высокой',
				'my shopping lists': 'Списки моих покупок',
				'remove shopping list': 'Удалить список покупок',
				'are you sure you want to remove the shopping list': 'Вы действительно хотите удалить список покупок',
				'removed': 'Удалено',
				'add to shopping list': 'Добавить в список покупок',
				'payments number': 'Количество платежей',
				'cvv: required error': 'Введите CVV',
				'enter cvv': 'Введите CVV',
				'cvv: please enter cvv error': 'Введите CVV',
				'cvv: minlength error': 'CVV должен содержать минимум 3 цифры',
				'cancel': 'Отмена',
				'continue with this cart': 'Продолжить с этой корзиной',
				'merge carts': 'Объединить корзины',
				'would you like to merge the two carts or continue using this cart': 'Вы хотите объединить 2 корзины, или продолжить с данной корзиной',
				'somewhere else': 'Где-нибудь еще',
				'you have an open cart with': 'У Вас открыта корзина с',
				'total weight': 'Общий вес',
				'account info': 'Мой профиль',
				'orders': 'Заказы',
				'payment methods': 'Способы оплаты',
				'coupons/discounts': 'Купоны/скидки',
				'save changes': 'Сохранить изменения',
				'credit cards': 'Кредитные карты',
				'paypal accounts': 'Аккаунты PayPal',
				'shopping list named': 'Список покупок под названием',
				'your account info has been successfully updated': 'Ваш профиль успешно обновлён',
				'was successfully created with': 'успешно создан с',
				'items successfully added': 'Товары успешно добавленны',
				'items were successfully added to shopping list': 'Товары успешно добавленны в список покупок',
				'item was successfully added to shopping list': 'Товары успешно добавленны в список покупок',
				'items were not added to shopping list': 'Товары не были добавленны в список покупок',
				'item were not added to shopping list': 'Товары не были добавленны в список покупок',
				'saved in': 'Сохраненно в',
				'we are not able to add the following products to your current order': 'Мы не можем добавить данные товары в текущий заказ',
				'opening hours': 'Время работы',
				'additional': 'Доп. информация',
				'area map': 'Карта местности',
				'contact us': 'Связаться с нами',
				'store information': 'Информация о магазине',
				'how can we help you today': 'Как мы можем вам сегодня помочь',
				'how can we help you': 'Как мы можем вам помочь',
				'send': 'Отправить',
				'sent successfully': 'Успешно отправленно',
				'successfully updated': 'Успешно обновленно',
				'successful update': 'Успешное обновление',
				'shopping list was successfully updated': 'Список покупок успешно обнавлен',
				'service provider blocker': 'Блокировка сервиса интернет-провайдером',
				'your service provider is blocking some of the website\'s functionality': 'Ваш интернет-провайдер  блокирует некоторые функции веб-сайта, мешая его полноценной работе',
				'once we complete picking your order, we adjust your final charge based on weight adjustments, substitutions & out of stock items': 'Окончательная стоимость заказаза будет откорректированна после его полной комплектации с учётом взвешивания, замен и отсутствующих товаров',
				'please contact your service provider': 'Пожалуйста, свяжитесь с вашим интернет-провайдером',
				'close': 'Закрыть',
				'passwords do not match': 'Пароли не совпадают',
				'send reset code': 'Отправить код переустановки',
				'are you sure you want to clear your cart': 'Вы уверены, что хотите очистить корзину',
				'e-mail': 'Электронная почта',
				'your message has been sent': 'Ваше сообщение успешно отправленно',
				'change zip code': 'Поменять индекс',
				'change area': 'Поменять регион',
				'hey there': 'Здравствуйте',
				'let\'s make sure we\'re available in your area': 'Давайте убедимся, что мы  доступны в вашем регионе',
				'please enter your zip code': 'Пожалуйста, введите ваш индекс',
				'please enter your area': 'Пожалуйста, введите ваш регион',
				'area: required error': 'Пожалуйста, введите ваш регион',
				'the area you entered, is served by this store': 'Регион, который вы указали, обслуживается данным магазином',
				'check': 'Проверка',
				'for ': 'для ',
				'we offer': 'Мы предлагаем',
				'both': 'варианты',
				'and': 'и',
				'and the': 'и',
				'pick up': 'Самовывоз',
				'options': '',
				'your store will be': 'Ваш магазин будет',
				'by changing your zip code you will lose your shopping cart': 'Изменив свой почтовый индекс, вы потеряете свою корзину покупок',
				'by changing your area you will lose your shopping cart': 'Изменив регион, вы потеряете свою корзину покупок',
				'go back': 'Назад',
				'don\'t change zip code': 'Не менять почтовый индекс',
				'don\'t change area': 'Не менять регион',
				'currently we do not deliver to your area': 'В настоящее время мы не осуществляем доставку в ваш регион',
				'change store': 'Поменять магазин',
				'go to store': 'Продолжить в магазин',
				'the zipcode you entered, is served by this store': 'Данный магазин предоставляет услуги доставки в указанном вами регионе',
				'next delivery regular': 'Дата следующей доставки',
				'thank you for contacting us': 'Спасибо, что обратились к нам',
				'delivery preferences': 'Предпочтения по доставке',
				'pickup preferences': 'Предпочтения по самовывозу',
				'privacy policy': 'Политика конфиденциальности',
				'about us': 'О нас',
				'not active': 'Не работает',
				'case of': 'Ящик',
				'case': 'Ящик',
				'single': 'Oдин',
				'ordersfromposfieldtitle0': 'Пожалуйста, введите номер мобильного телефона',
				'ordersfromposfielddescription0': 'Мы отправим вам контрольное сообщение',
				'phonenumber: required error': 'Номер телефона',
				'orderfromposcodesenttitle0': 'Мы отправили текстовое сообщение с кодом подтверждения на номер <%value%>',
				'confirmcode: required error': 'Подтвердить код',
				'hi': 'Привет',
				'entrance': 'Подъезд',
				'faq': 'Вопросы-ответы',
				'new password': 'Новый пароль',
				'resetnewpassword: required error': 'Переустановить новый пароль',
				'resetconfirmpassword: required error': 'Подтвердить новый пароль',
				'resetconfirmpassword: passwords do not match error': 'Пароли не совпадают',
				'resetnewpassword: minlength error': 'Пароль должен содержать мин.6 символов',
				'once we collected your order, we will call to collect your payment': 'Когда ваш заказ будет укомплектован, мы свяжемся с вами для произведения оплаты',
				'choose how you\'d like to pay': 'Выбрать способ оплаты',
				'your credit cards': 'Ваша кредитная карта',
				'your paypal accounts': 'Ваши аккаунт в -PayPal',
				'enter your credit on file': 'Пожалуйста, введите номер вашего клиентского счёта',
				'subtotal': 'Промежуточный итог',
				'substitutions choice': 'Выбрать замену',
				'let\'s change your password...': 'Давайте заменим ваш пароль...',
				'you must agree to the terms': 'Вы должны согласиться с условиями',
				'registration completed': 'Регистрация завершена',
				'registration is closed right now': 'Регистрация закрыта в данный момент',
				'please enter cvv': 'Пожалуйста, введите CVV',
				'pre auth: declined invld acct': 'номер CVV не совпадает с номером кредитной карты, попробуйте еще раз',
				'cvv is an anti-fraud security feature to help verify that you are in possession of your credit card': 'CVV - Credit Validation Value- это код защиты от мошенничества, который помогает убедиться, что кредитная карта находится у вас',
				'the three-digit cvv number is printed on the signature panel on the back of the card immediately after the card\'s account number': 'трехзначный номер CVV напечатан на панели подписи на обратной стороне кредитной карты',
				'select location': 'Выбрать местоположение',
				'pickup location': 'Местоположение самовывоза',
				'day0': 'Вск.',
				'day1': 'Пон.',
				'day2': 'Втр.',
				'day3': 'Срд.',
				'day4': 'Чтв.',
				'day5': 'Птн.',
				'day6': 'Субб.',
				'week_day_0': 'Вск.',
				'week_day_1': 'Пнд.',
				'week_day_2': 'Втр.',
				'week_day_3': 'Срд.',
				'week_day_4': 'Чтв.',
				'week_day_5': 'Птн.',
				'week_day_6': 'Субб.',
				'ticket_status_1': 'Новый',
				'ticket_status_2': 'В обработке',
				'ticket_status_3': 'Завершён',
				'ticket': 'Обращение',
				'by': 'от',
				'back to tickets': 'Вернуться обращениям',
				'me': 'Я',
				'status': 'Статус',
				'description': 'Описание',
				'created date': 'Дата открытия',
				'customer service': 'Служба поддержки',
				'service': 'Обслуживание',
				'inquiry': 'Обращение',
				'type in your comment': 'Введите комментарий здесь',
				'ticket id': 'Номер обращения',
				'no previous inquiries': 'Запросов нет',
				'we didn\'t recognize the address you entered': 'Мы не распознали адрес, который вы ввели',
				'sorry, currently we do not deliver to this area.': 'Сожалеем, но в настоящий момент мы не осуществляем доставку в ваш регион',
				'sorry, currently we do not deliver to this area, for further inquiries call': 'Сожалеем, но в настоящий момент мы не осуществляем доставку в ваш регион, для дополнительной проверки позвоните по номеру',
				'sorry, currently we do not deliver to this area, for further inquiries call our customer service': 'Сожалеем, но в настоящий момент мы не осуществляем доставку в ваш регион, для дополнительной проверки обратитесь в центр обслуживания клиентов',
				'sorry, branch change required': 'Сожалеем, требуется заменить отделение',
				'this address could only be delivered from branch': 'По данному адресу осуществляется доставка из отделения',
				'try another address or choose pickup option': 'Попробуйте другой адрес, или воспользуйтесь самовывозом',
				'within': 'внутри',
				'minutes':'минут',
				'hrs': 'часы',
				'review your order and process payment': 'Обзор заказа и способа оплаты',
				'what did you mean': 'Вы имели в виду',
				'please choose delivery time': 'Выбрать время доставки',
				'please choose pickup time': 'Выбрать время самовывоза',
				'delivery details': 'Сведения о доставке',
				'your details': 'Личные данные',
				'pickup details': 'Сведения о самовывозе',
				'street number and street name': 'Улица и номер дома',
				'apartment, suite, building, floor, etc.': 'Этаж, номер квартиры и т.д.',
				'type letter': 'Введите букву',
				'add products': 'Добавить товары',
				'add': 'Добавить',
				'choose exist list': 'Выбрать имеющийся список',
				'clear all': 'Очистить все',
				'or': 'или',
				'for': 'для',
				'to ': 'кому',
				'your order total {{areaname}}does not meet the {{minimum | currency}} minimum order total': 'Ваш заказ {{areaName}} еще не достиг минимума{{minimum | currency}}',
				'please add to your cart items worth {{difference | currency}} to proceed to checkout': 'Пожалуйста, добавьте в вашу корзину товары на сумму {{difference | currency}} для завершения покупки',
				'not including items on sale': 'Не включая товары по акции',
				'delivery cost': 'Стоимость доставки',
				'delivery fee': 'Стоимость доставки',
				'delivery cost tax': 'НДС стоимости доставки',
				'service fee': 'Цена за обслуживание',
				'service fee tax': 'НДС цены за обслуживание',
				'choose your pickup time': 'Выбрать время самовывоза',
				'pickup times & areas': 'Время и регионы самовывоза',
				'delivery times': 'Время доставки',
				'delivery times & areas': 'Время и регионы доставки',
				'delivery areas': 'Регионы доставки',
				'add your gift card': 'Оплата подарочными таллонами',
				'enter your gift card id': 'Введите номер подарочного таллона',
				'phone: pattern error': 'Номер телефона не верный',
				'phone: maxlength error': 'Номер телефона не верный',
				'phone: number error': 'Номер телефона не верный',
				'homephone: pattern error': 'Домашний номер телефона не верный',
				'homephone: number error': 'Домашний номер телефона не верный',
				'workphone: pattern error': 'Рабочий номер телефона не верный',
				'workphone: number error': 'Рабочий Номер телефона не верный',
				'mobilephone: pattern error': 'Мобильный номер телефона не верный',
				'mobilephone: number error': 'Мобильный номер телефона не верный',
				'pay by phone': 'Оплата по телефону',
				'get it within': 'Будет высланно в течение',
				'next delivery': 'Следующая доставка',
				'next pickup': 'Следующий самовывоз',
				'changes to your cart will be lost. are you sure you want to leave?': 'Изменения в вашей корзине будут утерянны, вы уверены, что хотите выйти?',
				'the following products were not saved correctly': 'Следующие продукты были неправильно сохранены',
				'the following products will not be for sale at this given time': 'На следующие товары нет скидки в выбранный промежуток времени',
				'shoplistname: required error': 'Пожалуйста, введите название списка покупок',
				'coupon number': 'Код купона',
				'delivery & pickup': 'Время доставки и самовывоза',
				'pickup times': 'Время самовывоза',
				'store map': 'Карта магазина',
				'none': 'нет',
				'look for the express pickup icon': 'Обратите внимание на значок быстрого самовывоза',
				'look for the express delivery icon': 'Обратите внимание на значок быстрой доставки',
				'invalid coupon code': 'Неверный код купона',
				'we couldn\'t find a matching coupon code': 'Код купона не найден',
				'where would you like to shop': 'Где вы хотите совершить покупку',
				'drop files here to upload or click to browse...': 'перетащите файлы здесь или нажмите, чтобы загрузить...',
				'drag files or browse...': 'перетащите файлы здесь или нажмите, чтобы загрузить...',
				'attachments': 'прикрепленные файлы',
				'link': 'ссылка',
				'select the store closer to your home': 'Выбрать ближайший к вашему дому магазин',
				'for this store we offer': 'Для данного магазина мы предлагаем',
				'buy from this store': 'Купить в этом магазине',
				'deliveries': 'Доставки',
				'dear customer': 'Дорогой покупатель',
				'please notice': 'Обратите внимание',
				'pseudoproductname: required error': 'Пожалуйста, введите название товара',
				'pseudoproductname: maxlength error': 'Название товара не должно содержать более 200 символов',
				'area': 'Регион',
				'recipe': 'Рецепт',
				'steps': 'Этапы',
				'more info': 'Доп. информация',
				'product description': 'Описание товара',
				'ingredients': 'Ингредиенты',
				'more information': 'Доп. информация',
				'directions': 'Описание',
				'nutrition facts': 'Пищевая маркировка',
				'serving size': 'Размер порции',
				'servings per container': 'Содержимое',
				'amount per serving': 'Вес на единицу',
				'calories': 'Калории(энергия)',
				'calories from fat': 'Калории из жира',
				'daily value': 'Суточная ценность',
				'total fat': 'Жиры',
				'saturated fat': 'Из них насыщенный жир',
				'trans fat': 'Из них транс жиры',
				'cholesterol': 'Холестерин',
				'sodium': 'Натрий',
				'total carbohydrate': 'Углеводы',
				'dietary fiber': 'Пищевые волокна',
				'sugars': 'Сахар',
				'protein': 'Белки',
				'vitamin a': 'Витамин A',
				'vitamin c': 'Витамин C',
				'calcium': 'Кальций',
				'iron': 'Железо',
				'g': 'г',
				'monounsaturated fat': 'Ненасыщенные жировые кислоты',
				'polyunsaturated fat': 'Полиненасыщенные жировые кислоты',
				'percent daily': 'Дневной процент потребления основан на суточной  диете в 2000 калорий. Ваше суточное значение может быть выше или ниже в зависимости от вашего потребления калорий.',
				'something went wrong': 'Что-то пошло не так',
				'total price is less then the minimum price': 'Общая сумма меньше минимальной суммы заказа',
				'not all of the given products were found': 'Не все товары найденны',
				'the following products are not active': 'Следующие товары не активированны',
				'add to shopping cart': 'Добавить в корзину',
				'select items and add to cart': 'Выбрать и добавить в корзину товары',
				'min': 'минуты',
				'from': 'Источник',
				'edit order': 'Редактировать заказ',
				'do you want to remove / add items to your existing order': 'Возможно вы хотите удалить / добавить продукты в существующий заказ',
				'note': 'Обратите внимание',
				'prices and specials will change according to the update date': 'Цены и акции изменятся в зависимости от даты изменения заказа',
				'more': 'еще',
				'less': 'меньше',
				'offer not found': 'Товар не найден',
				'product info disclaimer': 'Не следует полагаться на детали, указанные на сайте о составе продукта, могут быть ошибки или несоответствия в информации, точные данные указаны на товаре. Перед использованием проверьте данные на упаковке продукта.',
				'delivery hours': 'Время доставки',
				'delivery area': 'Регион доставки',
				'branch': 'Отделение',
				'quantity limitation': 'Ограничение количества',
				'is limited to ': 'Ограниченно до - ',
				'items only': 'Только элементы',
				'cart not found': 'Корзина не найдена',
				'existing member': 'Существующий член клуба',
				'connect your {clubname} membership account': 'Авторизация члена клуба	{clubName}',
				'join {clubname} membership account': 'Присоединиться к клубу {clubName}',
				'customer id': 'Номер клубной карты',
				'connect': 'Присоединиться',
				'connect_to_loyalty': 'Идентифицироваться',
				'could save': 'Могли бы сэкономить',
				'continue without membership': 'Продолжить как неавторизованный член клуба',
				'continue without club discounts': 'Продолжить без акций клуба',
				'you are now logged in and connected to your membership': 'На данный момент вы авторизированны в клубе клиентов',
				'membership club': 'Клуб клиентов',
				'connect to our membership club': 'Присоединитесь к нашему клиентскому клубу',
				'our clubs specials': 'Специальные предложения нашего клуба',
				'membership club number': 'Номер карты клуба',
				'membership club special deals': 'Скидки для членов клуба',
				'membership_club': 'Клиентский клуб',
				'{clubname} special deals': 'Особые предложения {clubname}',
				'expiration date': 'Срок действия',
				'you already a registered member': 'Вы уже зарегистрированный член клуба',
				'we did not find your membership club account': 'Ваше членство в клубе не найдено',
				'sorry': 'К сожалению',
				'you are now connected to your membership club account': 'Вы успешно присоединились к клубу клиентов',
				'you have successfully registered to {clubname}. you will need to connect as a club member, once you receive your credit card': '{clubname} Регистрация успешно завершена, авторизируйтесь как член клуба с момента получения карты клуба',
				'the registration process will end when you will pay a membership fee at checkout': 'Процесс регистрации завершится после оплаты членского взноса по окончании покупки',
				'remove credit card info': 'Удалить информацию о кредитной карте',
				'are you sure you want to remove credit card information': 'Вы уверены, что хотите удалить информацию о кредитной карте',
				'remove paypal account': 'Удалить аккаунт PayPal',
				'are you sure you want to remove this paypal account': 'Вы уверены, что хотите удалить аккаунт -PayPal',
				'are you sure you want to cancel your order update and return to your original order': 'вы уверены, что хотите отменить свой заказ, редактировать и вернуться к предыдущему заказу',
				'print': 'Печатать',
				'track order': 'Заказ номер',
				'view order': 'Просмотр заказа',
				'forgot something': 'Что-то забыли',
				'update order': 'Обновить заказ',
				'order received': 'Заказ получен',
				'in process': 'В обработке',
				'ready': 'Готов',
				'need help': 'Нужна помощь',
				'edit your order': 'Обновить заказ',
				'your order': 'Ваш заказ',
				'has been updated': 'Обновлён',
				'please check your email for order details': 'Пожалуйста, проверте вашу электронную почту для просмотра заказа',
				'thank you': 'большое спасибо',
				'we have started collecting your original order.': 'Комплектация вашего заказа начата',
				'we have started collecting your order.': 'Комплектация вашего заказа начата',
				'we have finished collecting your original order.': 'Комплектация вашего заказа  закончена',
				'we have finished collecting your order.': 'Комплектация вашего заказа завершена',
				'your order can no longer be changed': 'Ваш заказ  больше не может быть изменен',
				'your order cannot be changed at this time': 'В данный момент ваш заказ не может быть изменен',
				'cancel update': 'Отменить обновления',
				'canceling': 'Отмена',
				'update': 'Обновление',
				'replace shopping cart': 'Заменить корзину товаров',
				'are you sure you want to replace your shopping cart with the order from': 'Вы уверены, что хотите заменить вашу корзину товаров на заказ от',
				'replace': 'Заменить',
				'replace_2': 'Заменить',
				'you can still edit your order': 'Вы еще можете изменить свой заказ',
				'your original order is': 'Вашим основным заказом  является',
				'cancel order update': 'Отменить изменение заказа',
				'in process ': 'В процессе',
				'continue as new cart': 'Продолжить с новой корзиной',
				'you can move your selected items into a new cart': 'Вы можете добавить выбранные товары в новую корзину',
				'add your credit card': 'Добавить кредитную карту',
				'add your credit/debit card': 'Добавьте свои кредитные/дебетовые карты',
				'use my delivery address as my billing address*': 'Использовать мой адрес доставки в качестве платежного адреса*',
				'*by checking this option you approve transfer of your address data to the payment provider': 'Установив этот флажок, вы соглашаетесь на передачу данных о вашем адресе поставщику платежного сервиса.',
				'paypal': 'PayPal',
				'icreditmasterpass': 'Master Pass',
				'cost': 'Цена',
				'please wait': 'Пожалуйста, подождите',
				'checking your address': 'Ваш адрес проверяется',
				'missing details': 'Недостающие данные',
				'the server check your address': 'Мы проверяем возможность доставки по указанному адресу',
				'please enter your delivery address in order to see available delivery times': 'Пожалуйста  заполните ваш адрес чтобы увидеть доступное время доставки',
				'your cart is empty': 'Ваша корзина пуста',
				'personalid: required error': 'Пожалуйста, введите номер удостоверения личности',
				'customerid: required error': 'Пожалуйста, введите номер члена клуба',
				'storecredit: required error': 'Пожалуйста, введите платежные данные',
				'special_exp': 'Действует до',
				'special_exp_new': 'Действует до',
				'close more delivery times': 'Отметить дополнительное время доставки',
				'categories': 'Категории',
				'enter specials department': 'Войти в раздел Акции',
				'order by': 'Упорядочить по',
				'start shopping': 'Начать покупку',
				'choose an order': 'Выбрать заказ',
				'load more': 'Загрузить еще',
				'restore': 'Вернуть в корзину',
				'newsletter': 'Информационное письмо',
				'show more': 'Показать все',
				'show less': 'Показать меньше',
				'savings': 'Экономия',
				'membership savings': 'Экономия членов клуба',
				'potential membership savings': 'Потенциальная экономия членов клуба',
				'select delivery date & time': 'Выберите дату и время доставки',
				'select pickup date & time': 'Выбрать дату и время самовывоза',
				'the expiration of these': 'Срок годности',
				'sales will not be valid for this chosen time': 'Срок действия скидок на товары истечет в день доставки',
				'the special displayed below, will expire on the date you are supposed to receive your order': 'Срок действия акции истекает в день доставки заказа',
				'these items will be charged at their regular price': 'За данные товары будет взиматься их полная цена',
				'this product will be charged the regular price, promotion will not be applied': 'За данный товар будет взиматься его полная цена',
				'price of updated cart (est.)': 'Стоимость откорректированной корзины (прим.)',
				'price of updated service fee (est.)': 'Стоимость услуг (прим.)',
				'welcome': 'Добро пожаловать',
				'type in zip code': 'Введите индекс',
				'type in city': 'Введите  город',
				'verify_1': 'Пожалуйста подтвердите',
				'verify_2': 'ваш возраст',
				'verify_3': 'Ваш возраст должен быть не менее ',
				'verify_4': 'лет что бы',
				'verify_5': 'приобрести данный товар',
				'verify_6': 'Мне',
				'verify_7': 'больше',
				'verify_8': 'чем',
				'verify_9': 'меньше',
				'years': 'лет',
				'sorry_2': 'Приносим извинения',
				'you cannot purchase': 'Вы не можете приобрести',
				'this item': 'Данный товар',
				'login_tab_title': 'Войти на сайт',
				'register_tab_title': 'Зарегистрироваться',
				'club member name': 'Имя члена клуба',
				'our top picks': 'Топ предлжения',
				'got it': 'Начать покупку',
				'friend\'s email': 'Электронная почта друга',
				'or share your promo code': 'или поделитесь своим промокодом',
				'copy': 'Копировать',
				'send invitation': 'Отправить приглашение',
				'invitation sent successfully': 'Приглашение успешно отправлено',
				'congratulations': 'Специально для вас',
				'shop now': 'Начать покупку',
				'shop': 'Купить',
				'your order is being edited by another device': 'Ваш заказ обрабатывается на другом устройстве',
				'main menu': 'Главное меню',
				'invalid cart id': 'Неверный номер корзины',
				'please type in your zip code': 'Введите свой почтовый индекс',
				'find address': 'Найди адрес',
				'you cannot edit this field before entering a zip code for your address': 'Вы не можете вносить изменения в это поле, пока не введете почтовый индекс для своего адреса.',
				'please choose your address': 'Выберите свой адрес',
				'you will be able to edit the address details': 'Вы сможете изменить данные адреса',
				'you cannot edit this field': 'Вы не можете вносить изменения в это поле',

				/*pele card errors*/
				'permitted transaction.': 'Сделка разрешена.',
				'the card is blocked, confiscate it.': 'Карта заблокированна, конфисковать',
				'the card is stolen, confiscate it.': 'Карта краденная, конфисковать.',
				'contact the credit company.': 'Позвоните в свою кредитную компанию.',
				'refusal by credit company.': 'Отказ кредитной компании.',
				'the card is forged, confiscate it.': 'Кредитная карта поддельная, конфисковать.',
				'incorrect cvv/id.': 'Номер паспорта или CVV не верны',
				'an error occurred while building access key for blocked card files.': 'Не удалось создать ключ доступа для заблокированного файла.',
				'no communication. please try again or contact system administration': 'Нет связи. Пожалуйста попробуйте ещё раз или свяжитесь с системным администратором.',
				'the program was stopped by user`s command (esc) or com port can\'t be open (windows)': 'Программа была прекращена в соответствии с распоряжением оператора (ESC) или невозможно открыть COM PORT (WINDOWS).',
				'the acquirer is not authorized for foreign currency transactions': 'Нет разрешения на операции с иностранной валютой.',
				'this card is not permitted for foreign currency transactions': 'Для  данной карты нет разрешения на операции с иностранной  валютой.',
				'this card is not supported.': 'Данная карта не поддерживается.',
				'track 2 (magnetic) does not match the typed data.': 'Набранный номер не соответствует магнитной полосе.',
				'additional required data was entered/not entered as opposed to terminal settings (z field).': 'Дополнительные данные противоречат настройкам терминала.',
				'last 4 digits were not entered (w field).': 'Не набранны последние 4 цифры.',
				'entry in int_in file is shorter than 16 characters.': 'Запись в файле короче 16-ти символов INT_IN.',
				'the input file (int_in) does not exist.': 'Входной файл (IN_INT) не существует.',
				'blocked cards file (neg) does not exist or has not been updated, transmit or request authorization for each transaction.': 'Файл заблокированных карточек (neg) не существует , не был обновлен или необходима авторизация для каждой транзакции.',
				'one of the parameter files/vectors does not exist.': 'Один из файлов / векторов параметров не существует.',
				'date file (data) does not exist.': 'Файл даты (DATA) не существует.',
				'format file (start) does not exist.': 'Файл формата старт (START) не существует.',
				'the difference in days in the blocked cards input is too large, transmit or request authorization for each transaction.': 'Разница в днях приема входящий заблокированных карточек слишком велика, осуществите передачу или запросите авторизацию для каждой сделки.',
				'the difference in generations in the blocked cards input is too large, transmit or request authorization for each transaction.': 'Количество входящих заблокированных карточек слишком велико, необходима  авторизация для каждой сделки.',
				'when the magnetic strip is not completely entered, define the transaction as a telephone number or signature only.': 'Когда магнитная полоса не была введена полностью, определите сделку как телефонную или только с помощью личной подписи.',
				'the central terminal number was not entered into the defined main supplier terminal.': 'Номер центрального терминала не был введен в основной терминал поставщика.',
				'the beneficiary number was not entered into the defined main beneficiary terminal.': 'Номер получателя не был указан в основном терминале поставщика / получателя.',
				'the supplier/beneficiary number was entered, however the terminal was not updated as the main supplier/beneficiary.': 'Номер поставщика / получателя введён, но терминал не был обновлен в качестве основного поставщика / получателя.',
				'the beneficiary number was entered, however the terminal was updated as the main supplier': 'Номер получателя был введен, терминал был обновлен в качестве основного поставщика.',
				'old transactions, transmit or request authorization for each transaction.': 'Старые сделки, выполните трансляцию или запросите авторизацию по каждой сделке.',
				'defective card.': 'Ошибка в наборе номера кредитной карты, попробуйте еще раз',
				'this card is not permitted for this terminal or is not authorized for this type of transaction.': 'Данная карта не подходит для этого терминала или нет разрешения на данный вид сделки.',
				'this card is not permitted for this transaction or type of credit.': 'С данной карты не может быть совершена эта сделка или этот вид кредита.',
				'expired card.': 'Карта просрочена.',
				'installment error, the amount of transactions needs to be equal to: first installment plus fixed installments times number of installments.': 'Ошибка платежа, сумма сделки должна быть равна первому очередному платежу + (фиксированный платёж умноженный на кол-во платежей).',
				'unable to execute a debit transaction that is higher than the credit card`s ceiling.': 'Невозможно осуществить дебетовую сделку превышающую лимит кредитной карты.',
				'incorrect control number.': 'Ошибка в наборе номера кредитной карты, попробуйте еще раз.',
				'the beneficiary and supplier numbers were entered, however the terminal is defined as main.': 'Были введены номера получателя и поставщика, терминал определен как основной.',
				'the transaction`s amount exceeds the ceiling when the input file contains j1, j2 or j3 (contact prohibited).': 'Сумма сделки превышает  лимит, когда входной файл содержит j1, j2 или j3 (звонить нельзя).',
				'the card is blocked for the supplier where input file contains j1, j2 or j3 (contact prohibited).': 'Карта заблокирована для поставщика, в котором входной файл содержит j1, j2 или j3 (звонить нельзя).',
				'random where input file contains j1 (contact prohibited).': 'Выборочно, когда входной файл содержитJ1 (звонить нельзя).',
				'the terminal is prohibited from requesting authorization without transaction (j5).': 'Терминал не может запрашивать разрешение без сделки (j5).',
				'the terminal is prohibited for supplier-initiated authorization request (j6).': 'Терминал не может запрашивать разрешение по инициативе поставщика (j6).',
				'the terminal must request authorization where the input file contains j1, j2 or j3 (contact prohibited).': 'Терминал должен запросить разрешение, когда входной файл j1, j2 или j3 (звонить нельзя).',
				'secret code must be entered where input file contains j1, j2 or j3 (contact prohibited).': 'Необходимо ввести секретный код, когда входной файл содержит j1, j2 или j3 (звонить нельзя).',
				'incorrect vehicle number.': 'Неверный номер машины.',
				'the number of the distance meter was not entered.': 'Одометр не был введен.',
				'the terminal is not defined as gas station (petrol card or incorrect transaction code was used).': 'Терминал не настроен как топливная станция (проведена топливная карта или неверный код сделки).',
				'an id number is required (for israeli cards only) but was not entered.': 'Номер удостоверения личности не введён.',
				'cvv is required but was not entered.': 'Не введён CVV2',
				'cvv and id number are required (for israeli cards only) but were not entered.': 'Не введены номер удостоверения личности и CVV2.',
				'abs attachment was not found at the beginning of the input data in memory.': 'Не было найдено совпадений вначале входных данных в памяти ABS.',
				'the card number was either not found or found twice.': 'Номер карты не найден или найден дважды.',
				'incorrect transaction type.': 'Неверный тип сделки.',
				'incorrect transaction code.': 'Неверный код сделки.',
				'incorrect credit type.': 'Неверный  вид кредита.',
				'incorrect currency.': 'Неверная валюта',
				'the first installment and/or fixed payment are for non-installment type of credit.': 'Первый взнос и/или фиксированный платёж служат средством погашения кредита без рассрочки.',
				'number of installments exist for the type of credit that does not require this.': 'Количество платежей существует для определённого вида кредита, который этого не требует',
				'linkage to dollar or index is possible only for installment credit.': 'Привязка к доллару или индексу возможны только для потребительского кредита.',
				'the magnetic strip is too short.': 'Длина магнитной ленты слишком короткая.',
				'the pin code device is not defined.': 'Прибор для набора секретного кода не определен.',
				'must enter the pin code number.': 'Необходимо ввести секретный код.',
				'smart card reader not available - use the magnetic reader.': 'Устройство для чтения смарт-карт недоступно - используйте магнитный считыватель.',
				'must use the smart card reader.': 'Необходимо провести карту через устройство для чтения смарт-карт.',
				'denied - locked card.': 'Отказ - карта заблокирована.',
				'denied - smart card reader action didn\'t end in the correct time.': 'Отказ - процесс считывание смарт-карт не закончен в нужное время.',
				'denied - data from smart card reader not defined in system.': 'Отказ - данные, полученные от устройства для чтения смарт-карт не найдены в системе.',
				'incorrect pin code.': 'Неверный секретный код.',
				'currency does not exist in vector 59.': 'Валюта не существует в векторе 59.',
				'the club code entered does not match the credit type.': 'Введенный код клуба не соответствует типу кредита.',
				'cannot cancel charge transaction. please create a credit transaction.': 'Аннулирование оплаты по сделке невозможно, следует произвести возврат.',
				'unable to read/write/open the tran file.': 'Невозможно прочитать/написать/открыть файл TRAN.',
				'no authorization from credit company for clearance.': 'Нет разрешения от кредитной компании.',
				'the terminal is not permitted to send queries for immediate debit cards.': 'У терминала нет разрешения на отправку запроса о немедленном кредите.',
				'the transaction amount is too large, divide it into a number of transactions.': 'Сумма сделки слишком велика - разбить на несколько сделок.',
				'the terminal is not authorized to execute forced transactions.': 'Терминал не имеет разрешения на выполнение вынужденных сделок.',
				'the terminal is not authorized for cards with service code 587.': 'У терминала нет разрешения для карт с сервисным кодом 587.',
				'the terminal is not authorized for immediate debit cards.': 'Терминал не имеет разрешения на мгновенную дебетовую карту.',
				'the terminal is not authorized for installment transactions.': 'Терминал не имеет разрешения на сделку в рассрочку.',
				'the terminal is authorized for installment transactions only, not telephone/signature.': 'Терминал не уполномочен совершать сделки  по телефону или по подписи, только в рассрочку.',
				'the terminal is not authorized for telephone transactions.': 'Терминал не имеет разрешения на телефонную сделку',
				'the terminal is not authorized for signature-only transactions.': 'Терминал не имеет разрешения для сделок только с подписью.',
				'the terminal is not authorized for foreign currency transactions, or transaction is not authorized.': 'У терминала нет разрешения на сделку в иностранной валюте, или нет разрешения на сделку.',
				'the terminal is not authorized for club transactions.': 'У терминала нет разрешения на клубную сделку.',
				'the terminal is not authorized for star/point/mile transactions.': 'Терминал не имеет разрешения на сделки в «звездочках / баллах / милях».',
				'the terminal is not authorized for isracredit credit.': 'Терминал не имеет разрешения на получение кредита Isracredit.',
				'the terminal is not authorized for amex credit.': 'У терминала нет разрешения для Amex Credit.',
				'the terminal is not authorized for dollar linkage.': 'У терминала нет разрешения на привязку к доллару.',
				'the terminal is not authorized for index linkage.': 'У терминала нет разрешения на привязку к индексу цен.',
				'the terminal is not authorized for index linkage with foreign cards.': 'Терминал не имеет разрешения на привязку к индексу для иностранных карт.',
				'the terminal is not authorized for star/point/mile transactions for this type of credit.': 'Терминал не имеет разрешения на сделки «звездочки / баллы / почтовые уведомления для данного вида кредита.',
				'the terminal is not authorized for isra 36 credit.': 'Терминал не имеет разрешения на получение кредита исра 36.',
				'the terminal is not authorized for amex 36 credit.': 'Терминал не имеет разрешения на кредит Amex 36.',
				'the terminal is not authorized for this club code.': 'у терминала нет разрешения для этого кода клуба.',
				'the terminal is not authorized for immediate debit transactions (except for immediate debit cards).': 'Терминал не имеет разрешения на срочную дебетовую сделку (за исключением срочных дебетовых карт).',
				'the terminal is not authorized to accept visa card staring with 3.': 'У терминала нет разрешения принимать карты Visa, которые начинаются с -3.',
				'the terminal is not authorized to execute credit transactions above the ceiling.': 'Терминал не может выполнять кредитные сделки превышающие максимально разрешенный предел.',
				'the card is not permitted to execute club transactions.': 'Данной картой невозможно совершить клубную сделку.',
				'the card is not permitted to execute star/point/mile transactions.': 'Данной картой невозможно выполнить сделки со звездочками / баллами / милями.',
				'the card is not permitted to execute dollar transactions (regular or telephone).': 'Данной картой невозможно выпонить сделку в долларах (обычную или телефонную)',
				'the card is not valid according to isracard`s list of valid cards.': 'Данная карта недействительна в соответствии со списком действительных карт Isracard.',
				'defective card according to system definitions (isracard vector1), error in the number of figures on the card.': 'Карта неисправна в соответствии с определением системы (VECTOR1 Isracard) - количество цифр на карте неверно.',
				'the card is not permitted to execute dollar transactions according to system definitions (isracard vector1).': 'Данной картой невозможно выполнять долларовые сделки в соответствии с определением системы (VECTOR1 Isracard).',
				'the card belongs to a group that is not permitted to execute transactions according to system definitions (visa vector 20).': 'Карта принадлежит группе карт, которая не имеет права выполнять сделки в соответствии с определением системы (Visa VECTOR20).',
				'the card`s prefix (7 figures) is invalid according to system definitions (diners vector21).': 'Префикс карты (7 цифр) недействителен в соответствии с определением системы (21VECTOR of Diners).',
				'the card is not permitted to carry out installment transactions according to isracard`s list of valid cards.': 'Карта не может совершать сделки в рассрочку в соответствии со списком действительных карт Isracard.',
				'the number of installments is too large according to isracard`s list of valid cards.': 'Количество платежей слишком велико в соответствии со списком действительных карт Isracard.',
				'visa and diners cards are not permitted for club installment transactions.': 'Карты Visa и Diners не допускаются к совершению клубных сделок в рассрочку.',
				'series of cards are not valid according to system definition (isracard vector5).': 'Ряд карт недействителен в соответствии с определением системы (VECTOR5 Isracard).',
				'invalid service code according to system definitions (isracard vector6).': 'Недверный сервисный код в соответствии с определением системы (VECTOR6 Isracard).',
				'the card`s prefix (2 figures) is invalid according to system definitions (isracard vector7).': 'Префикс карты (2 цифры) неверный в соответствии с определением системы (VECTOR7 Isracard).',
				'invalid service code according to system definitions (visa vector12).': 'Неверный сервисный код в соответствии с определением системы (Visa VECTOR12).',
				'invalid service code according to system definitions (visa vector13).': 'Неверный сервисный код в соответствии с определением системы (Visa VECTOR13).',
				'immediate debit card is prohibited for executing credit transaction.': 'Запрещается совершать кредитные сделки мгновенной дебетовой картой.',
				'the card is not permitted to execute installment transactions according to alpha vector no. 31.': 'Данной картой невозможно осуществлять сделки в рассрочку в соответствии с 31 вектором карты Leumi.',
				'the card is not permitted for telephone and signature-only transactions according to alpha vector no. 31.': 'Данной картой невозможно осуществлять  Карта не может выполнять телефонные операции и операции только с подписью на основании vector no. 31 Leumi Card.',
				'the card is not permitted for telephone transactions according to alpha vector no. 31.': 'Данной картой невозможно выполнять телефонные сделки на основании альфа vector no. 31.',
				'credit is not approved for immediate debit cards.': 'Кредит не разрешен для срочных дебетовых карт.',
				'credit is not approved for foreign cards.': 'Кредит не разрешен для зарубежных карт.',
				'incorrect club code.': 'Неверный клубный код.',
				'the card is not permitted to execute flexible credit transactions (adif/30+) according to system definitions (diners vector21).': 'Данной картой невозможно выполнять гибкие кредитные сделки (желательно + 30) в соответствии с определением системы (21VECTOR of Diners).',
				'the card is not permitted to execute immediate debit transactions according to system definitions (diners vector21).': 'Данной картой невозможно выполнять немедленные дебетовые сделки в соответствии с определением системы (Diners VECTOR21).',
				'the payment amount is too low for credit transactions.': 'Сумма оплаты слишком мала для осуществления кредитной сделки.',
				'incorrect number of installments for credit transaction.': 'Количество платежей по кредитной сделке неверно.',
				'zero ceiling for this type of card for regular credit or credit transaction.': 'По данной карте для кредитной сделки установлен лимит 0',
				'zero ceiling for this type of card for immediate debit credit transaction.': 'По данной карте для срочной дебетовой сделки установлен лимит 0.',
				'zero ceiling for this type of card for immediate debit in dollars.': 'По данной карте для срочной дебетовой сделки в долларах установлен лимит 0.',
				'zero ceiling for this type of card for telephone transaction.': 'По данной карте для телефонной сделки установлен лимит 0.',
				'zero ceiling for this type of card for credit transaction.': 'По данной карте для кредитной сделки установлен лимит 0.',
				'zero ceiling for this type of card for installment transaction.': 'По данной карте для сделки в рассрочку  установлен лимит 0.',
				'american express card issued abroad not permitted for instalments transaction.': 'Невозможно осуществить сделку в рассрочку картой American Express, выданной за границей.',
				'jcb cards are only permitted to carry out regular credit transactions.': 'Картой JCB можно выполнить только обычную кредитную сделки.',
				'the amount in stars/points/miles is higher than the transaction amount.': 'Сумма в звездочках /баллах / милях превышает сумму сделки.',
				'the club card is not within terminal range.': 'Клубная карта не находится в пределах терминала.',
				'star/point/mile transactions cannot be executed.': 'Невозможно выполнить сделку в звездочках / баллах / милях.',
				'dollar transactions cannot be executed for this type of card.': 'Данной картой невозможно выполнить сделку в долларах.',
				'credit transactions cannot be executed with other than regular credit.': 'Невозможно совершить кредитную сделку иным способом отличным от обычного',
				'amount of discount on stars/points/miles is higher than the permitted.': 'Размер скидки в звездочках / баллах /милях превышает позволенный.',
				'forced transactions cannot be executed with credit/immediate debit card.': 'Невозможно совершить вынужденную сделку посредством кредита\\срочной дебетной карты.',
				'the previous transaction cannot be cancelled (credit transaction or card number are not identical).': 'Предыдущая сделка не может быть отменена (кредитная сделка или номер карты не совпадает).',
				'double transaction.': 'Двойная сделка.',
				'the terminal is not permitted for index linkage of this type of credit.': 'Терминал не имеет разрешения на привязку данного кредита к индексу.',
				'the terminal is not permitted for dollar linkage of this type of credit.': 'Терминал не имеет разрешения на привязку данного кредита к доллару.',
				'the card is invalid according to system definitions (isracard vector1).': 'Карта недействительна в соответствии с определением системы (вектор 1 Isracard).',
				'unable to execute the self-service transaction if the gas station does not have self service.': 'Невозможно совершить сделку посредством самообслуживания, если заправочные станции не предусматривают самообслуживания.',
				'credit transactions are forbidden with stars/points/miles.': 'Кредитная сделка с помощью звездочек /баллов / миль запрещена.',
				'dollar credit transactions are forbidden on tourist cards.': 'Запрещается совершать долларовые сделки туристической картой.',
				'phone transactions are not permitted on club cards.': 'Сделка по телефону невозможна для клубных карт.',
				'application error.': 'Ошибка приложения.',
				'invalid card': 'Карта недействительна',
				'transaction amount missing or zero.': 'Сумма сделки отсутствует или равна нулю.',
				'no communication to pelecard.': 'Нет связи с пеле-картой.',
				'doubled transaction.': 'Двойная сделка.',
				'terminal number does not exist.': 'Номер терминала не существует.',
				'terminal executes broadcast and/or updating data. please try again later.': 'Терминал в процессе трансляции и\\ или обновления, повторите попытку позже.',
				'user name and/or password not correct. please call support team.': 'Имя пользователя и / или пароль неверны, пожалуйста, обратитесь в службу поддержки.',
				'user password has expired. please contact support team.': 'Срок действия пароля истек, пожалуйста, обратитесь в службу поддержки.',
				'locked user. please contact support team.': 'Пользователь заблокирован, пожалуйста, обратитесь в службу поддержки.',
				'blocked terminal. please contact account team.': 'Терминал заблокирован, пожалуйста, обратитесь в службу поддержки.',
				'token number abnormal.': 'Номер талона неверный.',
				'user is not authorized in this terminal.': 'Пользователь не может выполнять сделки на этом терминале.',
				'validity structure invalid. use mmyy structure only.': 'Неверная структура годности, Использовать только сруктуру MMYY.',
				'ssl verifying access is blocked. please contact support team.': 'Доступ к проверке сертификата безопасности заблокирован. Обратитесь в службу поддержки.',
				'data not exist.': 'Нет данных.',
				'general error. please contact support team.': 'Общая ошибка, пожалуйста, обратитесь в службу поддержки.',
				'necessary values are blocked/wrong.': 'Необходимые значения  заблокированны / неверны.',
				'general error. repeat action.': 'Общая ошибка, пожалуйста, обратитесь в службу поддержки.',
				'necessary values missing to complete installments transaction': 'Необходимые значения отсутствуют для завершения сделки в рассрочку.',
				/*end pele card errors*/

				'video': 'Видео',
				'user not found': 'Имя пользователя или пароль не верны',
				'in {name} dep.': 'В отделе { наз.}',
				'in specials': 'по акции',
				'coupons': 'Купоны',
				'my coupons': 'Мои купоны',
				'no coupons available at this time. check again later or check our specials!': 'На данный момент купонов нет. Повторите попытку позже или ознакомьтесь с нашими акциями.',
				'giftcards.0: gift card must be up to 255 characters error': 'Подарочная карта может содержать до 255 символов',
				'your membership has expired': 'Ваше членство в клубе истекло',
				'hello': 'Здравствуйте',
				'your club membership has expired on': 'Ваше членство в клубе истекло',
				'membership number': 'Членский номер',
				'renew you membership and enjoy exclusive benefits and discounts': 'Обновите свое членство в клубе и получите льготы и скидки уже в этой покупке',
				'please call customer service for renewal': 'Для обновления членства в клубе и использования льгот уже в этой покупке позвоните в службу поддержки клиентов',
				'loyalty registration was not fully completed, registration voucher will be sent by mail': 'Регистрация в бонусной программе не завершена, регистрационный ваучер будет отправлен вам на электронную почту',
				'pay attention': 'Обратите внимание',
				'enter a valid number': 'Введите действительный номер',
				'paying with different payment method will': 'Оплата разными способами приведёт к',
				'discard your loyalty club discounts': 'отмена льгот по бонусой программе',
				'do you want to continue': 'Хотите продолжить',
				'return and pay with loyalty credit card': 'Вернуться и оплатить при помощи бонусной кредитной карты',
				'no thanks, continue shopping': 'Продолжить без скидок',
				'renew membership': 'Обновление членства клуба',
				'loyalty membership renewal': 'Обновление членства в бонусной программе',
				'your membership has been renewed successfully': 'Ваше членство успешно обновлено',
				'you can enjoy from the club benefits in this order': 'Вы можете воспользоваться льготами клуба уже в этом заказе',
				'continue': 'Продолжить',
				'loyalty_club_continue': 'Продолжить',
				'loyalty card id': 'Номер бонусной карты',
				'clip & add': 'Добавить',
				'clip': 'Добавить',
				'personal id': 'Номер удостоверения личности',
				'we are not able to register your membership club account': 'Невозможно зарегистрировать вас в членском клубе',
				'please make sure your details are correct or try again later': 'Убедитесь, что введенная вами информация верна или повторите попытку позже',
				'new member': 'Новый член клуба',
				'{retailername} membership offers exclusive': 'Членство в клубе {retailername} предоставляет вам',
				'promotions in-store and online': 'льготы, скидки и специальные предложения',
				'join our membership and enjoy exclusive benefits and promotions': 'Присоединяйтесь к нашему клубу клиентов и получайте уникальные преимущества и скидки',
				'the items in your shopping cart will be cleared if you': 'Ваша корзина будет очищена в случае,если',
				'choose to edit your previous order': 'выберете редактировать ваш предыдущий заказ',
				'edit order & clear cart': 'Редактировать заказ и очистить корзину',
				'it seems you are editing your order on a different device/browser.': 'Вы редактируете заказ на другом устройстве / браузере',
				'it seems you have finished editing your order on a different device/browser.': 'Вы закончили  редактировать заказ с другого устройства / браузера',
				'do you want to add the product to this new order or to the order that is being updated?': 'Вы хотите добавить продукт в этот новый заказ или в заказ, находящийся в режиме редактирования?',
				'note! you in the middle of editing order': 'Обратите внимание! Вы находитесь в процессе оформления заказа',
				'you in the middle of editing order. changing store is impossible': 'Вы находитесь в процессе оформления заказа. Невозможно поменять магазин',
				'note! it seems you have finished editing your order on a different device/browser.': 'Обратите внимание! Вы уже оформили заказ на другом устройстве / браузере',
				'add to new order': 'Добавить в новый заказ',
				'add to updated order': 'Добавить в редактированный заказ',
				'join our membership club': 'Присоединиться к нашему клубу',
				'join': 'Присоединяйтесь',
				'join the club': 'Войти в клубу',
				'join / connect to club': 'Присоединиться к клубу',
				'connect to club': 'Присоединиться к клубу',
				'this email already exists': 'адрес электронной почты уже существует в системе',
				'unable to create user': 'Не удалось создать пользователя',
				'delivery_type_1_times': 'Сроки доставки',
				'delivery_type_2_times': 'Сроки самовывоза',
				'packed': 'Собран',
				'frombranch': 'из отделения',
				'special type': 'Тип акции',
				'select from variety': 'Выбрать из ассортимента',
				'forpackquantity': 'за',
				'pack': 'Упаковка',
				'forbidden action': 'Запрещенное действие',
				'internal server error': 'Внутренняя ошибка сервера',
				'please leave the order outside the door if nobody\'s home': 'Прошу оставить доставку возле двери в случае, если никого нет дома',
				'identify': 'Идентифицировать',
				'show full details in {clubname} club page': 'Показать подробности на страничке сайта {clubName}',
				'enter your promo code': 'Введите свой промо код',
				'promo code unidentified': 'Промо код не обнаружен',
				'promocode: promo code unidentified error': 'Промо код не обнаружен',
				'promo code': 'Промо-код',
				'for {clubname} full details': 'Для всех подробностей {clubname}',
				'click here': 'Нажмите здесь',
				'home phone number': 'Номер домашнего телефона',
				'work phone number': 'Номер рабочего телефона',
				'mobile phone number': 'Номер мобильного телефона',
				'special': 'Акция',
				'check all | clicking the action buttons above as "add to cart" refers to selected items': 'Отметить всё /Нажатие функциональной кнопки "Добавить в корзину" относится к выбранным товарам',
				'back to previous orders': 'Вернуться к предыдущим заказам',
				'save changes before leaving page?': 'Сохранить изменения перед тем, как покинуть страницу?',
				'don\'t save': 'Не сохранять',
				'back to my shopping lists': 'Вернуться к моим спискам покупок',
				'add file': 'Добавить файл',
				'no attachments': 'Нет прикрепленных файлов',
				'buy': 'Купить',
				'no purchases have been made yet, previous orders will be saved on this screen': 'Покупки еще не совершены, предыдущие заказы будут сохраненны на этом экране',
				'no shopping list created yet': 'Перечень покупок еще не создан',
				'all departments': 'Каталог товаров',
				'{retailer} club members can save time and upload their shopping cart from their last visit to the store': 'Члены клуба {retailer} могут сэкономить время и загрузить корзину покупок с прошлого посещения магазина.',
				'upload my cart': 'Загрузить корзину покупок',
				'no, thanks': 'Нет, спасибо',
				'approve & register': 'Разрешить и закончить регистрацию',
				'not approve': 'Не разрешаю',
				'i agree to receive promotional content & offers from {company_name} and {hubname}': 'Я согласен/а получать рекламную рассылку и акции от компании  {company_name}, {hubname}',
				'unfortunately, your cart cannot be uploaded': 'К сожалению, не удается загрузить вашу корзину',
				'please try again later or continue shopping': 'Пожалуйста, попробуйте позже, или продолжите покупку на сайте',
				'areaquery: spautocompleterequirematch error': 'Пожалуйста, выберите адрес из списка',
				'areaquery: required error': 'Пожалуйста, выберите адрес из списка',
				'pickup address': 'Адрес самовывоза',
				'the pickup will be picked from': 'Самовывоз осуществляется из отделения',
				'additional special\'s variety': 'Дополнительное разнообразие акционных товаров',
				'password: invalid password error': 'Новый пароль должен включать от 6 до 20 символов',
				'customer service order': 'Заказ службы поддержки клиентов',
				'logo': 'Логотип',
				'copy completed': 'Копирование завершено',
				'edit comment': 'Оформить комментарий',
				'filters': 'Фильтры',
				'close filters': 'Закрыть фильтры',
				'cant find item image': 'Не удается найти изображение товара',
				'plus': 'плюс',
				'minus': 'минус',
				'open recipe': 'Открыть рецепт',
				'banner': 'Баннер',
				'play video': 'Включить видео',
				'remove from cart': 'Удалить из корзины',
				'express delivery': 'Быстрая доставка',
				'express pickup': 'Быстрый самовывоз',
				'paypal logo': 'Логотип PayPal',
				'masterpass logo': 'Логотип MasterPass',
				'language': 'Язык',
				'account': 'Аккаунт',
				'open store info': 'Открыть информацию о магазине',
				'close store info': 'Закрыть информацию о магазине',
				'open/close cart': 'Открыть/закрыть корзину',
				'open ticket': 'Открыть билет',
				'close ticket': 'Закрыть билет',
				'paypal account': 'Аккаунт PayPal',
				'previous product': 'Предыдущий продукт',
				'next product': 'Следующий продукт',
				'disclaimer': 'Условия акции',
				'the previous items': 'К предыдущим товарам',
				'the next items': 'К следующим товарам',
				'open filter options': 'Открыть опции фильтров',
				'close filter options': 'Закрыть опции фильтров',
				'clear': 'Очистить',
				'open product page': 'Открыть страницу продукта',
				'open': 'Открыть',
				'close our top picks': 'Закрыть наши Топ предложения',
				'clarification': 'Пояснение',
				'show category': 'Показать категорию',
				'show order': 'Показать заказ',
				'close order': 'Закрыть заказ',
				'cancel order': 'Отменить заказ',
				'on credit payment': 'Оплата в кредит',
				'please enter order confirmation number': 'Пожалуйста, введите код подтверждения заказа',
				'a confirmation email was sent to {email}': 'письмо с подтверждением будет выслано на адрес {email}',
				'your payment method will not be charged': 'оплата взиматься не будет с вашего способа оплаты',
				'user not connected to organization': 'Пользователь не подключен к организации',
				'no delivery time slots available': 'Доставка не выполняется',
				'no pick up time slots available': 'Самовывоз недоступен',
				'invalid phone number': 'Неверный номер телефона',
				'phones - invalid phone number.': 'Неверный номер телефона',
				'invalid city': 'Неверное название города',
				'invalid address': 'Неверный адрес',
				'invalid street': 'Неверное название улицы',
				'invalid address 1': 'Улица и номер дома указаны неверно',
				'invalid address 2': 'Этаж и квартира неверны',
				'invalid zip code': 'Неверный почтовый индекс',
				'shopping cart': 'Корзина покупок',
				'main manu': 'Главное меню',
				'home page banner': 'баннер домашней страницы',
				'promoted specials': 'Ведущие акции',
				'filters & sorting': 'Фильтрование и сортировка товаров',
				'product list': 'Список товаров',
				'checkout footer': 'Нижний колонтитул- checkout',
				'delivery method': 'Способ доставки',
				'delivery method & details': 'Способы и подробности доставки',
				'navigation': 'Навигация',
				'main display': 'Основной дисплей',
				'shortcuts': 'Ярлыки',
				'filters_region': 'Фильтр товаров',
				'languages': 'Языки',
				'settings': 'Настройки системы',

				'go to specials': 'Ко всем скидкам',
				'prutah_checkout_finish_thank_you_line_1': 'Благодарим, за покупку',
				'prutah_checkout_finish_thank_you_line_2': 'в',
				'entrance, apartment, suite, building, floor, etc.': 'подъезд, этаж, № квартиры и т.д.',
				'pickup fee': 'Стоимость самовывоза',
				'items to cart': 'Товары в корзину',
				'order in process': 'Заказ в обработке',
				'order ready': 'Заказ готов',
				'you can move the selected items into a new order': 'Вы можете перенести выбранные товары в новый заказ',
				'next slide': 'Следующая картинка',
				'home page link': 'Ссылка на домашнюю страницу',
				'newsletter bubble': 'Информационная рассылка',
				'number of items': 'Количество товаров',
				'cart_total': 'Итого',
				'items_in_cart': 'товаров',
				'review our last minute recommendations, just before you checkout': 'Перед тем как завершить покупку вас могут заинтересовать наши предложения',
				'thank you, you are now logged in': 'Благодарим, вы успешно вошли в систему',
				'thank you, registration completed successfully': 'Благодарим, вы успешно зарегистрировались',
				'delivery method options': 'Возможные способы доставки',
				'substitute preference options': 'Способы замены товаров',
				'payment method options': 'Возможные способы оплаты',
				'add coupon': 'Добавить купон',
				'remove product': 'Удалить товар',
				'all specials': 'Все акции',
				'sorry, we do not deliver to your area': 'Извините, но в данный момент мы не осуществляем доставку в ваш регион',
				'please type in your email and we will let you know when we expand to your location': 'Оставте нам ваш электронный адрес и мы сообщим когда будут выполняться доставки в ваш регион',
				'email address': 'электронный адрес',
				'keep in touch': 'Сообщить мне',
				'back to address screen': 'Вернуться к выбору региона',
				'please enter a more accurate address to continue': 'Дя того, что бы продолжить, введите более точный адрес',

				'express delivery is allowed only with products that contain express delivery tag': 'Экспресс доставка возможна только для товаров, отмеченных как товары экспресс доставки',
				'this service is available for express items only. removing non express items from your cart will enable using this option': 'Данная услуга доступна только для товаров экспресс доставки, удаление несоответствующих товаров из вашей корзины позволит воспользоваться этой услугой',
				'express delivery products limit exceeded': 'Количество товаров для экспресс доставки превышает разрешаемый минимум',
				'express delivery is limited to': 'Лимит для экспресс доставки состаляет',
				'items. to enable this service, remove items from your cart': 'Только товары. Что бы воспользоваться этой услугой, удалите товары из вашей корзины',

				'please enter a delivery address': 'Введите адрес доставки',
                'or select a store for pickup': 'Или выберите магазин для самовывоза',
                'select a store for pickup': 'выберите магазин для самовывоза',
                'choose a convenient time & pickup your order at one of our stores': 'Выберите  удобное время и заберите свой заказ в одном из наших магазинов',
                'please enter a valid address': 'Способы доставки доступные в данном регионе',
                'see you soon': 'До встречи в магазине',
                'at_hour': 'В',
                'the waiting period will not exceed two hours': 'Доставка в течение 2-х часов',
                'attachment.size - invalid attachment, file bigger then 2mb': 'Ошибка: размер файла более 2 МБ',
                'attachment.originalname - invalid attachment name': 'Ошибка: недопустимое имя файла',
                'attachment.mimetype - invalid attachment, file type not allowed': 'Ошибка: недопустимый тип файла',
				'employee discount': 'Скидка работникам',
                'card id': 'Номер карты',
                'discount': 'Скидка',
                'remaining obligo': 'Остаток бюджета',
                'monthly obligo': 'Ежемесячный бюджет',
                'obligo type': 'Тип бюджета',
                'monthly discount obligo': 'ежемесячный бюджет скидки',
                'cardid: required error': 'Пожалуйста, введите номер карты',
                'pin: required error': 'Пожалуйста, введите Пин код',
				'employee discount card holder?': 'Держатель дисконтной карты работника?',
                'employee discount card data': 'Данные вашей дисконтной карты работника',
				'employee discount account not found': 'Дисконтная карта работника не найдена',
				'organization': 'Организация',
				'organization management': 'Управление организацией',
				'no available deliveries in the upcoming days': 'Нет доставки в ближайшие дни',
				'please enter your house number': 'Пожалуйста, введите номер дома',
				'so we can assign you to the right branch': 'чтобы мы определили правильное отделение для вас',

				'your products from a website': 'Ваши продукты с сайта',
				'inventory availability may vary according to the delivery area': 'Наличие товаров может меняться в зависимости от зоны доставки',
				'please note - we could not identify {number}': 'Обратите внимание - мы не смогли определить {number}',
				'more products': 'других продуктов',
				'more product': 'другой продукт',
				'from the {name} website': 'с сайта {name}',
				'out of stock': 'нет в наличии',

				'your account has not yet been verified via email - please verify by clicking on the link sent to your email inbox': 'Ваша учетная запись еще не была подтверждена с помощью электронной почты - пожалуйста, подтвердите, нажав на ссылку, отправленную на ваш почтовый ящик',
				'send again': 'Отправить повторно',
				'didn\'t receive an email': 'Не получили электронную почту',
				'user email address not found': 'Адрес электронной почты пользователя не найден',
				'a verification email will be sent to your email box. the link is valid for {link_ttl} minutes': 'На ваш электронный адрес будет отправлено письмо с подтверждением. Нажмите ссылку в течение следующих {link_ttl} минут',
				'your account requires activation, email with activation link have been sent to your email. the link is valid for {link_ttl} minutes': 'Ваш аккаунт требует активации, письмо со ссылкой для активации было отправлено на ваш адрес электронной почты. Нажмите ссылку в течение следующих {link_ttl} минут',
                'your account requires activation.': 'Ваш аккаунт требует активации.',
                'email with activation link have been sent to your email.': 'Письмо со ссылкой для активации было отправлено на ваш адрес электронной почты.',
                'the link is valid for {link_ttl} minutes': 'Ссылка активна в течение следующих {link_ttl} минут',
				'activation code expired': 'Срок действия кода активации истек',
				'website login': 'Вход на сайт',
				'send a new link': 'Отправить новую ссылку',
				'sorry, this link has expired': 'Извините, срок действия этой ссылки истек',
				'a new link can be sent': 'Можно отправить новую ссылку',
				'you have successfully registered for the site': 'Вы успешно зарегистрировались на сайте',
				'this account has already been verified': 'Пользователь уже активирован',
				'you may enter the trade site': 'Вы можете войти на сайт',
				'this account is an organizational account. you may need to verify it similarly from time to time': 'Эта учетная запись является учетной записью организации. Время от времени вам может потребоваться аналогичная активация',
				'please verify your identity': 'Пожалуйста, подтвердите вашу личность',
				'to verify your identity, please enter a phone number': 'Чтобы подтвердить свою личность, введите номер телефона',
				'continue to registration': 'Продолжить регистрацию',
				'phone number does not match': 'Номер телефона не совпадает',
				'user invitation not found': 'Приглашение пользователя не найдено',
				'user already registered': 'Пользователь уже зарегистрирован',
				'phone number hint': 'подсказка номера',

				'add users': 'Добавить пользователей',
				'adding users': 'Добаление пользователей',
				'remove users': 'Удаление пользователей',
				'remove user': 'удалить',
				'please select a file': 'Пожалуйста выберите файл',
				'search for a user by phone or email': 'Поиск пользователя по телефону или почте',
				'download csv example': 'Скачать пример CSV файла',
                'uploading a csv file': 'Загрузить CSV файл',
                'file upload complete. a summary email will be sent to your inbox': 'Загрузка файла завершена. Вам на почту будет отправлено письмо с результатами',

                'in order to add users to the organization, a csv file must be uploaded with the users information': 'Для того что-бы добавить пользователей к организации, загрузите файл CSV с информацией о пользователях',
                'once added to the system, an identity verification email will be sent to each of the users': 'После добавленния в систему, на электронный адрес пользователя будет отправлено письмо с приглашением',
                'only after identity verification, users will be able to complete the registration process for the site': 'Только после прохождения верефикации, у пользователей будет возможность завершить процес регистрации на сайте',
                'in order to remove users from the organization, a csv file must be uploaded with the users details': 'Для того что-бы удалить пользователей из организации, загрузите файл CSV с информацией о пользователях',
				'pay attention! these users will no longer be able to access the organization website': 'Внимание! Эти пользователи больше не будут иметь доступ к сайту организации',
				'the file must contain at least one user with a phone number and email': 'Файл дожен содержать как минимум одного пользователя с правильным email и телефоном',
				'account verification succeed': 'Пользователь успешно активирован',
				'required fields are marked with *': 'Обязательные поля обозначены с*',

				'user products list not found': 'Список продуктов пользователя не найден',
				'user products list is disabled for this retailer': 'Список продуктов пользователя отключен для этого сайта',
				'my lists': 'Мои списки',
				'my products': 'Мои продукты',
				'my products list': 'Мои продукты',
				'the products i usually purchase': 'Продукты, которые я покупаю',
				'your time is precious': 'Ваше время дорого',
				'we have prepared a list of your personal products': 'Мы подготовили список ваших личных товаров',
				'we have prepared a list of products you usually purchase': 'Мы подготовили список товаров которые вы покупаете',
				'add products from the list': 'Добавить товары из списка',
				'in cart': 'В корзине',

				'we use cookies to improve your experience': 'Мы используем файлы cookie, чтобы вам было удобнее',
				'we use cookies or other trackers to facilitate the use of the site, improve the performance and security of the site, personalize the content offered, including personalized advertising content, and compile traffic and navigation statistics. some cookies, other than functional and security cookies, require your consent to be placed.': 'Мы используем файлы cookie или другие трекеры для облегчения использования сайта, повышения его производительности и безопасности, персонализации предлагаемого контента, включая персонализированный рекламный контент, и сбора статистики трафика и навигации. Для размещения некоторых файлов cookie, кроме функциональных файлов cookie и файлов cookie для обеспечения безопасности, требуется ваше согласие. ',
				'accept': 'Принять',
				'accept all cookies': 'Принять все файлы cookie',
				'setup my cookies': 'Настроить мои файлы cookie',
				'managing cookies': 'Управляющие файлы cookie',
				'these cookies allow you to benefit from the services offered by the site, to optimize its use and personalization (in particular personalization of offers and advertisements) depending on your profile.': 'Эти файлы cookie позволяют вам пользоваться сервисами, предлагаемыми сайтом, оптимизировать его использование и персонализацию (в частности, персонализацию предложений и рекламы) в зависимости от вашего профиля.',
				'marketing': 'Маркетинг',
				'marketing cookies are used to improve the relevance of the ads you see and to make sure that you do not see the same ad multiple times.': 'Маркетинговые файлы cookie используются для повышения релевантности рекламных объявлений, которые вы видите, и для того, чтобы вы не видели одну и ту же рекламу несколько раз.',
				'additional features': 'Дополнительных функций',
				'these cookies make it possible to activate additional features on the site, for example to facilitate your purchases.': 'TЭти файлы cookie позволяют активировать на сайте дополнительные функции, например, для облегчения ваших покупок.',
				'audience measurement': 'Измерения аудитории',
				'these cookies allow us to obtain navigation statistics on our site. we use this data to identify possible malfunctions of the site, to improve the site, for marketing purposes, and in order to personalize your experience on the site.': 'Эти файлы cookie позволяют нам получать статистику навигации на нашем сайте. Мы используем эти данные для выявления возможных сбоев в работе сайта, улучшения сайта, в маркетинговых целях и для персонализации вашего пребывания на сайте.',
				'google analytics': 'Google Analytics',
				'these cookies allow us to obtain statistics on the number of visits to our site (e.g., number of visits, most viewed pages, etc.). we use this data to understand our users better, check the performance of our content, get insights, and identify possible malfunctions of the site to make improvements.': 'Эти файлы cookie позволяют нам получать статистику о количестве посещений нашего сайта (например, количество посещений, наиболее просматриваемые страницы и т.д.). Мы используем эти данные, чтобы лучше понимать своих пользователей, проверять эффективность своего контента, получать аналитические данные и определять возможные сбои в работе сайта для внесения улучшений.',
				'functional (not configurable)': 'Функциональные (ненастраиваемые)',
				'these cookies allow you to remember your choices and preferences on your account and to provide features that facilitate the use of the site. they are essential for the proper functioning of the site and are strictly necessary for the provision of a service that you have expressly requested.': 'Эти файлы cookie позволяют запоминать ваши варианты выбора и предпочтения в вашей учетной записи и предоставлять функции, облегчающие использование сайта. Они обязательны для правильного функционирования сайта и строго необходимы для предоставления сервиса, который вы прямо запросили.',
				'time slot unavailable': 'Временной интервал доставки недоступен',
				'we\'re sorry, this time slot is no longer available. please select a different time': 'Сожалеем, этот временной интервал больше не доступен. Пожалуйста, выберите другое время доставки',
				'return to schedule': 'Вернуться к расписанию',
				'sitemap': 'Карта сайта',
				'pages': 'Страницы',
				'products marked with': 'Товары, отмеченные',
				'limited quantity': 'Ограниченное количество',
				'tag are limited to': 'тег ограничены',
				'items only.': 'только предметы.',
				'the {product_count} products listed below will be': '{product_count} следующих продуктов будут',
				'removed from your cart': 'удалены из корзины',
				'out of stock products': 'следующих товаров нет в наличии',
				'confirm': 'подтвердить',
				'back to cart': 'назад в корзину',
				'the items below will be removed from your cart': 'следующие товары будут удалены из вашей корзины',
				'{product_count} out of stock products will not be supplied': '{product_count} товар(ов) отсутствуют на складе и поэтому не будут доставлены',
				'{coupons_count} expired coupons cannot be redeemed': 'срок действия {coupons_count} купон(ов) истек и их нельзя использовать',
				'expired': 'срок действия истек',
                'are you sure you want to remove all products from your shopping cart?': 'Вы уверены что хотите удалить все продукты с Вашей корзины?',
                'yes, clear cart': 'да, очистить корзину',
                'clear cart?': 'Очистить корзину?',
				'supervisions': 'Кашрут',
				'registration attempt has been blocked, please contact support': 'Попытка регистрации заблокирована, обратитесь в службу поддержки',
				'account has been suspended, please contact support': 'Аккаунт заблокирован, обратитесь в службу поддержки',
				'tavplus': 'Tav Plus+',
				'your tav plus': 'Ваш Tav Plus',
				'add voucher': 'Добавить ваучер',
				'tav_plus_credit_card_disclaimer_short': 'Использование ваучеров ограничено вводом кредитной карты в качестве резервного средства платежа. Окончательная сумма счета за заказ будет определена в момент получения товара и может отличаться из-за легковесных, отсутствия или замены...',
				'tav_plus_credit_card_disclaimer_long': 'Использование ваучеров ограничено вводом кредитной карты в качестве резервного средства платежа. Окончательная сумма счета за заказ будет определена в момент получения товара и может отличаться из-за легковесных, отсутствия или замены товаров. В случае превышения суммы, введенной для оплаты заказа, или остатка ваучера, остаток суммы будет списан с кредитной карты. Ваучеры/подарочная карта должны храниться до получения заказа и соответствующего списания средств, при условии, что это оговорено в правилах. Скидка для сотрудников не предоставляется при оплате через «Тав Плюс+»',
				'for additional terms and details': 'Дополнительные условия и подробности',
				'go to site': 'смотрите на сайте',
				'your voucher details': 'Данные вашего ваучера',
				'please enter your voucher number': 'пожалуйста, введите номер вашего ваучера',
				'voucher number': 'Номер ваучера',
				'if your voucher is a plastic card, please keep it in your possession until receiving your order': 'Если ваш ваучер представляет собой пластиковую карту, пожалуйста, храните ее при себе до получения заказа',
				'balance': 'Баланс',
				'amount to pay': 'Сумма к оплате',
				'delivery-fee-explain-default': 'Окончательная сумма платы за доставку будет обновлена при оформлении заказа и может быть изменена в зависимости от региона/времени доставки или любых дополнительных скидок. ',
				'delivery_type_1_fee': 'Kомиссия доставка',
				'delivery_type_2_fee': 'Kомиссия самовывоз',
				'includes_delivery_type_1_fee': 'Bключает комиссию в размере доставка',
				'includes_delivery_type_2_fee': 'Bключает комиссию в размере самовывоз',
				'not_includes_delivery_fee': '(без учета стоимости доставки)',

				'your points': 'Ваши баллы',
				'buy with points': 'Купить за баллы',
				'almost expired': 'Срок действия почти истек',
				'coupon clipped': 'Купон использован',
                'add product': 'Добавить товар',
                'product in cart': 'Товар в корзине',
                'clip coupon': 'Использовать купон',
				'clipped externally': 'Получен в другом месте',
				'personal coupons': 'Персональные купоны',
				'may interest you': 'Вас может заинтересовать',
				'clip for {x} points': 'Использовать за {x} баллов',
				'not enough points': 'Недостаточно баллов',
				'coupon expires today': 'Срок действия купона истекает сегодня',
				'coupon expires in {x} days': 'Срок действия купона истекает через {x} дней',
				'clip for': 'Использовать за',
				'valid': 'Действует',
				'can be redeemed once': 'Можно использовать один раз',
				'can be redeemed {x} times': 'Можно использовать {x} раз',

				'last_minute_recommendations_headline': 'у нас есть рекомендации специально для вас.',
				'last_minute_recommendations_title_coupons': 'Купоны, которые могут сэкономить ваши деньги',
				'last_minute_recommendations_title_products_usually': 'Возможно, вы забыли эти продукты?',
				'last_minute_recommendations_title_products_complementary': 'Вам также может понравиться...',
				'last_minute_recommendations_title_specials_offers': 'Специальные предложения, которые вы не должны пропустить',
				'last_minute_recommendations_title_products_offers': 'Вы не должны пропустить эти продукты',

				'add_units_special': 'Добавьте {remaining} единиц, чтобы воспользоваться специальным предложением',
				'add_currency_special': 'Добавьте товар на {remaining}, чтобы воспользоваться специальным предложением',
				'add_measure_special': 'Добавьте {remaining} {unit}, чтобы воспользоваться специальным предложением',
				'there are no available items for this promotion': 'Нет доступных товаров для этой акции',
				'cart deleted': 'Корзина очищена',
				'no_coupons_for_filter': 'Извините<br>Мы не смогли найти купон "{coupon_filter}" ',
				'search coupon': 'Искать купон',

				'add_units_coupon': 'Добавьте еще {remaining} товаров, чтобы получить скидку',
				'add_currency_coupon': 'Добавьте еще {remaining} товаров, чтобы получить скидку',
				'add_measure_coupon': 'Добавьте еще {remaining} {unit}, чтобы получить скидку',
				'add_units_cart_coupon': 'Добавьте {remaining} товаров',
				'add_unit_cart_coupon': 'Добавьте {remaining} товар',
				'add_currency_cart_coupon': 'Добавьте {remaining}',
				'add_measure_cart_coupon': 'Добавьте {remaining} {unit}',
				'add_units_special_purchase': 'Добавьте еще {remaining}',
				'buy_units_total': 'Купите {total} товаров',
				'buy_unit_total': 'Купите {total} товаров',
				'buy_currency_total': 'Купите за {total}' ,
				'buy_measure_total': 'Купите {total} {unit}',

				'suggestions_dialog_header_1': 'Рекомендации по замене',
				'suggestions_dialog_header_2': 'выберите замену из предлагаемых нами аналогичных продуктов',
				'suggestions_dialog_header_3': 'Нет на складе',
				'suggestions_dialog_header_4': 'Рекомендации по замене:',
				'ok, continue' : 'Ок, ПРОДОЛЖИТЬ',
                'change chosen time': 'изменить дату доставки',
				'hover description for cvv': 'Номер CVV («Проверочный параметр карты») на вашей кредитной или дебетовой карте представляет собой трехзначный номер на фирменных кредитных и дебетовых картах VISA, Mastercard и Discover. на кредитной или дебетовой карте American Express это будет четырехзначный цифровой код.',
				'enter cvv number': '',
				'select pickup time': 'выберите время получения',
				'select delivery time': 'выберите время доставки',
				'please notice, the expiration of these {count} {type} will not be valid for this chosen time': 'пожалуйста, обратите внимание, срок годности этих {count} {type} не будет действителен для выбранного времени',
				'the coupon displayed below, will not be valid for this chosen time': 'купон, отображенный ниже, не будет действителен для выбранного времени',
				'these {count} coupons will not be valid for this chosen time': 'эти {count} купоны не будут действительны для выбранного времени',
				'please notice, the coupon displayed below, will not be valid for this chosen time': 'пожалуйста, обратите внимание, купон, отображенный ниже, не будет действителен для выбранного времени',
				'please notice, the special displayed below, will not be valid for this chosen time': 'пожалуйста, обратите внимание, специальное предложение, отображаемое ниже, не будет действительным для выбранного времени',
				'price does not include bottle deposit': 'Цена не включает стоимость бутылки',
				'price includes bottle deposit': 'Цена включает стоимость бутылки',
				'gift_card_error_message': 'Мы не смогли обработать вашу ПОДАРОЧНУЮ КАРТУ. Пожалуйста, попробуйте еще раз или используйте другую карту',
				'wrong_gift_card_amount_error': 'Сумма превышает стоимость корзины',
				'amount_exceeds_balance': 'Сумма превышает баланс',
				"sorry, currently we don't deliver to this address for further inquiries call to the customer service.": 'К сожалению, в настоящее время мы не осуществляем доставку по данному адресу. Для дальнейшей информации обратитесь в службу поддержки.',
				'storage instructions': 'Правила хранения',
				'gg_map_dialog_title': 'Выберите адрес',
				'gg_map_dialog_hint': 'При определении вашего точного местоположения возникли проблемы. Пожалуйста, убедитесь, что вы точно расположили метку местоположения. Это поможет нам определить ваш точный адрес',
				'gg_map_alert_need_location': 'Необходимо дать разрешение на доступ к данным геолокации, чтобы вы могли далее выбрать свое местоположение в Google Maps',
				'gg_map_alert_error': 'Есть проблемы при отображении карт Google.',
				'gg_map_alert_unsupport': 'Геолокация не поддерживается этим браузером.',
				'autocomplete_adrs_didnt_find': 'Вы не нашли свой адрес?',
				'autocomplete_adrs_choose_location': 'Выберите свое местоположение на карте',
				'sorry, your city was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'K сожалению, ваш город не найден. Выберите адрес из предложений автозаполнения или закрепите свой адрес c помощью опции Google Maps.',
				'sorry, your address was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'K сожалению, ваш адрес не найден. Выберите адрес из предложений автозаполнения или закрепите свой адрес c помощью опции Google Maps.',
				'sorry, your zip code was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'К сожалению, ваш почтовый индекс не найден. Пожалуйста, выберите адрес из предложенных в автозаполнении или укажите адрес с помощью опции google maps.',
				'country_of_origin_title': 'Страна происхождения',
				'origin_country_legal_text': 'Обратите внимание, что страна происхождения указана на момент заказа. Фактически страна происхождения поставленного продукта может быть другой в зависимости от наличия продукта на складе в момент доставки',
				'after package deduction': 'После вычета расходов на упаковку',
				'country: required error': 'Пожалуйста, введите страну',
				'january': 'январь',
				'february': 'февраль',
				'march': 'Маршировать',
				'april': 'апрель',
				'may': 'Может',
				'june': 'Июнь',
				'july': 'Июль',
				'august': 'Август',
				'september': 'Сентябрь',
				'october': 'Октябрь',
				'november': 'ноябрь',
				'december': 'Декабрь',
				'redeemed': 'погашено',
				'points gained': 'Получено баллов',
				'source': 'источник',
				'when': 'Когда',
                'retrieving data. wait a few seconds and try to cut or copy again': 'Этот адрес правильный? Вы можете изменить или уточнить его',
				'is this address correct? you can now edit and refine it': 'Этот адрес правильный? Вы можете изменить или уточнить его',
                'edit your address': 'Изменить адрес',
				'searchtext_storename_placeholder': '{Название магазина}',
				'contact_us': 'Связь с нами',
                'how_can_we_help_you_today': 'Как мы можем помочь вам?',
                'leave_us_a_message': 'Оставьте нам сообщение, и мы свяжемся с вами в ближайшее время',
                'fullname': 'Полное имя',
                'message': 'Как мы можем вам помочь?',
                'your_message_has_been_sent': 'Ваше сообщение отправлено',
                'thank_you_for_contact_us': 'Благодарим Вас за обращение к нам',
				'balance to be paid': 'Остаток к оплате',
				'redeem': 'Применить',
				'please save the voucher or gift card until you receive your order and are charged': 'Пожалуйста, сохраните сертификат или подарочную карту до получения вашего заказа и списания средств',
				'choose gift card/s': 'Выберите ПОДАРОЧНЫЕ КАРТЫ',
				'add gift card': 'добавить подарочную карту',
				'redeem gift card & discount cards': 'Чтобы использовать ПОДАРОЧНУЮ КАРТУ',
				'card number': 'номер карты',
				'please enter the card number to check the balance': 'Для проверки баланса введите номер карты',
				'customer support phone number': 'Номер телефона службы поддержки клиентов',
				'complete_and_proceed_to_checkout': 'Завершить и перейти к оплате',
				'please notice, the following products are not available on this chosen time': 'Обратите внимание, что следующие продукты недоступны в выбранное время',
				'the following products are not available on this chosen time': 'Обратите внимание, что следующие продукты недоступны в выбранное время',
				'if you continue, they will be removed from your cart': 'Если вы продолжите, они будут удалены из вашей корзины',
				'verification challenge expired. check the checkbox again.':'Время проверки истекло. Установите флажок и повторите попытку.',
				'result for “{title}”': 'Результаты по “{title}”',
				'find a product': 'Найти продукт',
				'brand Results:': 'Результаты по бренду:',
				'please enter cvv to continue': 'Пожалуйста, введите CCV, чтобы продолжить',
				'checkout is opened on another screen': 'Оформление заказа открыто на другом экране',
				'please wait, we are loading the products': 'Пожалуйста, подождите, мы загружаем товары',
				'loading coupons, please wait...': 'Мы загружаем купоны. Пожалуйста, подождите...',
                'receipt': 'квитанция',
				'address_form_field_label_city': 'Город',
				'address_form_field_label_address': 'Адрес',
				'address_form_field_label_zip_code': 'Почтовый индекс',
				'address_form_field_label_postal_code': 'Почтовый индекс',
				'address_form_field_label_entrance': 'Номер подъезда',
				'address_form_field_label_apartment_number': 'Номер квартиры',
				'address_form_field_label_building_code': 'Номер здания',
				'address_form_field_label_state': 'Страна',
				'address_form_field_label_comments': 'Примечания',
				'address_form_field_label_apartment_suite_unit': 'Квартира/Апартаменты/Жилая единица',
                'address_form_field_label_providence': 'Провиденс',
                'address_form_field_label_more_details': 'Дополнительная информация',
                'address_form_field_label_floor': 'этаж',
                
                'address_drop_pin_message_1': 'Местоположение успешно закреплено. Измените при необходимости.',
                'address_drop_pin_message_cancel': 'Отменить закрепленный адрес',

				'autocomplete_city_not_found_non_droppin': 'К сожалению, ваш город не найден. Пожалуйста, выберите адрес из списка или обратитесь в службу поддержки за помощью.',
				'autocomplete_address_not_found_non_droppin': 'К сожалению, ваш адрес не найден. Пожалуйста, выберите адрес из списка или обратитесь в службу поддержки за помощью.',
				'autocomplete_zip_code_not_found_non_droppin': 'К сожалению, ваш почтовый индекс не найден. Пожалуйста, выберите адрес из списка или обратитесь в службу поддержки за помощью.',	

                'address_form_field_error_city: required error': 'Пожалуйста, введите корректный город',
                'address_form_field_error_address: required error': 'Пожалуйста, введите корректный адрес',
                'address_form_field_error_zip_code: required error': 'Пожалуйста, введите корректный почтовый индекс',
                'address_form_field_error_postal_code: required error': 'Пожалуйста, введите корректный индекс',
                'address_form_field_error_entrance: required error': 'Пожалуйста, введите корректный вход',
                'address_form_field_error_apartment_number: required error': 'Пожалуйста, введите корректный номер квартиры',
                'address_form_field_error_building_code: required error': 'Пожалуйста, введите корректный код здания',
                'address_form_field_error_state: required error': 'Пожалуйста, введите корректный штат',
                'address_form_field_error_comments: required error': 'Пожалуйста, введите комментарий',
                'address_form_field_error_apartment_suite_unit: required error': 'Пожалуйста, введите корректную квартиру/свиту/единицу',
                'address_form_field_error_providence: required error': 'Пожалуйста, введите корректный регион',
                'address_form_field_error_more_details: required error': 'Пожалуйста, введите больше деталей',
                'address_form_field_error_floor: required error': 'Пожалуйста, введите действительный этаж',
                'address_form_field_error_floor: min error': 'Пожалуйста, введите действительный этаж',
                'address_form_field_error_floor: number error': 'Пожалуйста, введите действительный этаж',
                
                'account_details_form_personal_details_title': 'Личные данные',
                'account_details_form_personal_details_save_btn': 'Сохранить персональные данные',
                'account_details_form_address_title': 'Адрес',
                'account_details_form_address_save_btn': 'Сохранить адрес',

				'api_error_message': 'Произошла ошибка{error_message} (ошибка {error_code}). Повторите попытку через несколько минут. Если проблема не исчезнет, обратитесь в службу поддержки клиентов',
				'payment error': 'Ошибка оплаты',
				'error received': 'Произошла ошибка',
				'please check your credit card details or contact your credit card provider': 'Проверьте данные своей кредитной карты или свяжитесь с поставщиком кредитной карты',

				'pickup time:': 'Время самовывоза:',
                'pickup address:': 'Адрес самовывоза:',
                'delivery time:': 'Время доставки:',
                'delivery address:': 'Адрес доставки:',
                'on_day': '',
                'wish_to_change_delivery':'Хотите изменить время доставки?',
                'wish_to_change_pickup':'Хотите изменить время самовывоза?',
                'select date & time':'Выберите дату и время',
                'save & change delivery time':'Сохранить и изменить время доставки',
                'save & change pickup time':'Сохранить и изменить время самовывоза',
                'back to home page':'Вернуться на главную страницу',
                'your order has been updated':'Ваш заказ обновлен',

				'checkout_finish_last_update_time_delivery': 'Вы можете обновить время доставки заказа до',
				'checkout_finish_last_update_time_pickup': 'Вы можете обновить время самовывоза заказа до',
				'forgot_sth_want_to_change_delivery': 'Хотите изменить время доставки?',
				'forgot_sth_want_to_change_pickup': 'Хотите изменить дату самовывоза?',

				'update_order_popup_title_def': 'Вы можете изменить порядок товаров или время {delivery method}',
				'update_order_popup_order_item_def': 'изменить товары в заказе',
				'update_order_popup_delivery_time_def': 'обновить время доставки',
				'update_order_popup_pickup_time_def': 'обновить время самовывоза',
				'update_order_popup_cancel_def': 'Хотите отменить заказ? Нажмите здесь, чтобы отменить {cancel order}',
				'update_order_popup_lastest_time_def': 'Вы можете изменить время {delivery method} вашего заказа до {time calculate}',
				'update_order_popup_err_cant_update_time': 'Обновление времени {delivery method} недоступно',

				'irre_sales_collect_one': 'Этот {saleType} станет недействителен в новое указанное время и будет рассчитан по обычной цене',
				'irre_sales_collect_many': 'Эти {count} {saleType} станут недействительны в новое указанное время и будут рассчитываться по обычной цене',
				'irre_sales_checkout_one': 'При изменении этого заказа {saleType} станет недействителен и цена товара будет рассчитана по обычной цене',
				'irre_sales_checkout_many': 'При изменении заказа {count} {saleType} станут недействительны и цена товара будет рассчитана по обычной цене',
				'irre_sales_oos_one': 'Эти товары в настоящее время недоступны и будут удалены из вашей корзины',
				'irre_sales_oos_many': 'Эти товары в настоящее время недоступны и будут удалены из вашей корзины',
				'irre_sales_btn_continue': 'Да, обновить',
				'irre_sales_btn_change_slot': 'изменить дату {delivery method}',
				'irre_sales_btn_cancel_update': 'Отменить обновления',
				'thank you for your order!': 'Спасибо за ваш заказ!',

				'delivery_type_1': 'Доставка',
				'delivery_type_2': 'самовывоза',
				'delivery_type_3': 'Выбрать и уйти',

				"add your credit card": "Добавьте свою кредитную карту",
				"payment methods": "Способы оплаты",
				"your credit cards": "Ваши кредитные карты",
				"add a credit card": "Добавить кредитную карту",
				"gift cards": "Подарочные карты",
				"add gift cards": "Добавить подарочные карты",
				"choose gift card/s": "Выберите ПОДАРОЧНУЮ КАРТУ(-Ы)",
				"please enter the card number to check balance": "Для проверки баланса введите номер карты",
				"{gift card name} details": "Информация о {название подарочной карты}",
				"on the next screen, you can select your payment amount": "На следующем экране вы сможете выбрать сумму платежа",
				"balance:": "Баланс:",
				"please enter amount to pay": "Введите сумму для оплаты",
				"amount exceeds balance": "Сумма превышает баланс",
				"please save the voucher or gift card until you receive your order and are charged.": "Сохраните ваучер или подарочную карту до получения вашего заказа и списания средств.",
				"remove card": "Удалить карту",
				"see more": "узнать больше",
				"show less": "Показать меньше",
				"amount exceeds shopping cart value": "Сумма превышает стоимость корзины",
				'delivery_disclaimer_default_text':'Окна доставки можно выбирать только во время оформления заказа',
				'missing premise or street number': 'Необходимо ввести номер дома',

				'default_first_time_popup_title': 'Добро пожаловать!',
				'default_first_time_popup_sub_title': 'Как бы вы хотели получить свой заказ?',
				'default_first_time_popup_sub_title_pickup': 'Выберите ближайший магазин для удобного самовывоза!',
				'default_first_time_popup_sub_title_delivery': 'Введите свой адрес, чтобы увидеть доступные варианты доставки рядом с вами',
				'default_returning_popup_title': 'Изменить регион',
				'default_returning_popup_sub_title': 'Как бы вы хотели получить свой заказ?',
				'default_returning_popup_sub_title_pickup': 'Выберите новое место самовывоза',
				'default_returning_popup_sub_title_delivery': 'Найдите новые варианты доставки',
			
				'default_one_store_popup_title': 'Отличная новость!',
				'default_one_store_popup_sub_title': 'Мы можем выполнить доставку в ваш регион!',
				'default_one_store_popup_continue_button_text': 'Продолжить покупки',
			
				'default_multiple_store_popup_title': 'Вам повезло!',
				'default_multiple_store_popup_sub_title': 'У нас есть несколько магазинов, обслуживающих ваш регион',
				
				'default_no_store_popup_title': 'Нам очень жаль',
				'default_no_store_popup_sub_title': 'В настоящее время мы не осуществляем доставку в ваш регион. Оставьте свой адрес электронной почты, и мы сообщим вам, когда у нас появятся дополнительные варианты.',

				'delivery_input_zip_code_placeholder': 'Почтовый индекс (например: 75011)',
				'back_btn_text': 'Назад',

				'try a different location': 'Попробуйте выбрать другое место'
			};
		}]);

})(angular, app);
