(function (angular, app) {
    'use strict';

    app.service('ChooseAreaDialog', [
        '$injector', '$rootScope', '$location', 'Dialog', 'LocalStorage', 'DeliveryWithinDaysWarningDialog',
        function ($injector, $rootScope, $location, Dialog, LocalStorage, DeliveryWithinDaysWarningDialog) {
            var Util,
                Cart;

            this.show = show;

            /**
             * Retrieves template information for the choose area dialog.
             * Determines the appropriate dialog template URL and CSS style class
             * based on the advanced area availability setting and retailer's supported
             * delivery methods.
             *
             * @returns {Object} An object containing the dialog template URL and style class.
             */

            function _getTemplateInfo(){
               var info = {
                dialogTemplateUrl: '',
                styleClass: ''
               };

                if($rootScope.advancedAreaAvailabilityEnabled) {
                    info.dialogTemplateUrl = 'template/dialogs/choose-area-advanced/index.html';
                    info.styleClass = 'choose-area-dialog advanced-area-availability';
                } else {
                    info.dialogTemplateUrl = 'template/dialogs/choose-area/index.html';
                    info.styleClass = 'choose-area-dialog multi-domains-retailer';  //leave 'multi-domains-retailer' class to support backwards
                }

                return info;
            }

            function show(disableClosing, changeArea, areaName, lastActiveElementEvent, openPickup) {
                Util = Util || $injector.get('Util');
                Cart = Cart || $injector.get('Cart');

                var queryAreaName = null;
                var search = $location.search();

                if (search.hubChildrenSearchText) {
                    queryAreaName = search.hubChildrenSearchText;
                    $location.search('hubChildrenSearchText', null);
                }

                $rootScope.isChangeStore = !!$rootScope.config.area;

                var lastActiveElementSelector = lastActiveElementEvent && Util.uniqueSelectorByElement(lastActiveElementEvent.target || lastActiveElementEvent);
                lastActiveElementSelector && LocalStorage.setItem('lastActiveElementSelector', lastActiveElementSelector);

                //== when cart is in Update Mode - branch switching is disabled - show popup
                var templateInfo = _getTemplateInfo();
            
                if (Cart.editOrderId) {
                    return Util.showCommonDialog({
                        title: '{{\'Edit Order\' | translate}}',
                        content: '{{\'You in the middle of editing order. Changing store is impossible\' | translate}}',
                    }).then(function(){
                        throw 'Cannot change area when editing order';
                    });
                }

                sessionStorage.setItem('chooseAreaDialogShown', 1);
                return Dialog.show({
                    templateUrl: templateInfo.dialogTemplateUrl,
                    controller: 'ChooseAreaCtrl as chooseAreaCtrl',
                    ariaLabelledby: 'choose_area_dialog_title',
                    styleClass: templateInfo.styleClass,
                    disableClosing: !!disableClosing,
                    locals: {
                        areaName: areaName || null,
                        queryAreaName: queryAreaName,
                        doNotAllowChooseRetailers: changeArea,
                        openPickup: openPickup
                    }
                }).then(function (response) {
                    Cart.validateDeliveryItemsLimit();
                    DeliveryWithinDaysWarningDialog.show();

                    return response;
                });
            }
        }
    ]);

    app.run(['$rootScope', 'ChooseAreaDialog', function ($rootScope, ChooseAreaDialog) {
        $rootScope.ChooseAreaDialog = ChooseAreaDialog;
    }]);

    app.controller('ChooseAreaCtrl', [
        '$rootScope', '$scope', '$timeout', 'LocalStorage', 'Config', 'Util', 'Dialog', 'SpDeliveryAreasService', '$element',
        'SpProductTags', 'doNotAllowChooseRetailers', 'areaName', 'queryAreaName', 'openPickup', 'SP_SERVICES', 'DELIVERY_TIMES_TYPES', 'User', 'DataLayer','Cart',
        function ($rootScope, $scope, $timeout, LocalStorageService, Config, Util, Dialog, SpDeliveryAreasService, $element,
                  SpProductTags, doNotAllowChooseRetailers, areaName, queryAreaName, openPickup, SP_SERVICES, DELIVERY_TIMES_TYPES, user, DataLayer, Cart) {
            var chooseAreaCtrl = this;
            $scope.Util = Util;

            chooseAreaCtrl.getBranchLocation = SpDeliveryAreasService.getAreaAddressText;
            chooseAreaCtrl.filterFoundAreas = filterFoundAreas;
            chooseAreaCtrl.goToFilterAreas = goToFilterAreas;
            chooseAreaCtrl.isShowDeliveryWithinDaysWarning = isShowDeliveryWithinDaysWarning;
            chooseAreaCtrl.onKeyDown = onKeyDown;
            chooseAreaCtrl.isBranchNameHidden = isBranchNameHidden;
            chooseAreaCtrl.showTab = showTab;
            chooseAreaCtrl.addStyle = addStyle;
            chooseAreaCtrl.getDeliveryInputPlaceholder = getDeliveryInputPlaceholder;

            chooseAreaCtrl.filterAreas = {
                autoComplete: filterAreasAutoComplete,
                submit: filterAreasSubmit,
                resetErrors: filterAreasResetErrors,
                pickup: filterAreasPickup
            };
            chooseAreaCtrl.chooseArea = {
                submit: chooseAreaSubmit
            };
            chooseAreaCtrl.areaNotFoundLog = {
                submit: submitAreaNotFoundLog
            };

            var chooseAreaAdvancedDialogs = {
                PICKUP_AND_DELIVERY: 'template/dialogs/choose-area-advanced/pickup-and-delivery/index.html',
                NO_PICKUP: 'template/dialogs/choose-area-advanced/no-pickup/index.html',
                NO_STORE_FOUND: 'template/dialogs/choose-area-advanced/delivery-result/no-store-found/index.html',
                ONE_STORE_FOUND: 'template/dialogs/choose-area-advanced/delivery-result/one-store-found/index.html',
                MULTIPLE_STORE_FOUND: 'template/dialogs/choose-area-advanced/delivery-result/multiple-store-found/index.html',
                CHOOSE_PICKUP_TAB: 'template/dialogs/choose-area-advanced/choose-pickup-tab/index.html',
                CHOOSE_DELIVERY_TAB: 'template/dialogs/choose-area-advanced/choose-delivery-tab/index.html'
            };

            var searchMessageModes = {
                NO_STORE_FOUND: {
                    id: 0,
                    name: 'No Stores Found',
                    property: 'noStore',
                    defaultPopupTitle: 'default_no_store_popup_title',
                    defaultPopupSubTitle: 'default_no_store_popup_sub_title'
                },
                ONE_STORE_FOUND: {
                    id: 1,
                    name: 'One Store Found',
                    property: 'oneStore',
                    defaultPopupTitle: 'default_one_store_popup_title',
                    defaultPopupSubTitle: 'default_one_store_popup_sub_title',
                    defaultContinueButtonText: 'default_one_store_popup_continue_button_text'
                },
                MULTIPLE_STORE_FOUND: {
                    id: 2,
                    name: 'Multiple Stores Found',
                    property: 'multipleStore',
                    defaultPopupTitle: 'default_multiple_store_popup_title',
                    defaultPopupSubTitle: 'default_multiple_store_popup_sub_title'
                }
            };

            _init();
            SpDeliveryAreasService.setPreferredRetailerId(Number(LocalStorageService.getItem('preferredRetailerId')) || undefined);

            /**
             * Init
             * @private
             */
            function _init() {
                if ($rootScope.advancedAreaAvailabilityEnabled && Config.retailer.advancedAreaAvailabilitySettings) {
                   filterAreasPickup();
                   _initAdvancedAreaAvailability();
                   $scope.defaultTabId = chooseAreaCtrl.currentAdvancedAreaAvailabilitySettings.firstDefaultTab ? 1 : 0;
                
                   addStyle();
                   var defaultTab = chooseAreaCtrl.tabs.find(function(tab) {
                       return tab.id === $scope.defaultTabId;
                   })
                   showTab(defaultTab);
                }

                // In case the dialog is open to change the area send event to analytics
                if (chooseAreaCtrl.isChangeArea) {
                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Change Store Popup'}});
                }

                return Config.initPromise.then(function () {
                    return SpDeliveryAreasService.getChooseAreaMode(!doNotAllowChooseRetailers);
                }).then(function (mode) {
                    chooseAreaCtrl.isChangeArea = !!Config.branchAreaId;
                    chooseAreaCtrl.chooseAreaMode = mode;
                    chooseAreaCtrl.chooseAreaText = (Config.retailer.chooseAreaText || []).find(function(text) {
                        return text.languageId === Config.language.id
                    });
                    if (mode === SP_SERVICES.CHOOSE_AREA_MODE.CHOOSE_RETAILER) {
                        chooseAreaCtrl.visibleState = chooseAreaCtrl.chooseArea;
                        delete chooseAreaCtrl.visibleState.back;
                        SpDeliveryAreasService.getAreas().then(function (areas) {
                            _setFoundAreas(areas);
                        });
                    } else {
                        SpDeliveryAreasService.getPickupAreas().then(function (pickupAreas) {
                            chooseAreaCtrl.pickupAreas = pickupAreas;

                            if (openPickup || $rootScope.advancedAreaAvailabilityEnabled) {
                                filterAreasPickup();
                            }
                        });

                        goToFilterAreas();

                        if (queryAreaName) {
                            chooseAreaCtrl.queryArea = queryAreaName;
                            chooseAreaCtrl.filterAreas.submit();
                            return;
                        }

                        // In case areaName is sent in locals use it immediately
                        if (areaName) {
                            chooseAreaCtrl.area = areaName;
                            chooseAreaCtrl.filterAreas.submit();
                        }
                    }
                });
            }

            // start advanced area availability setting
            function _initAdvancedAreaAvailability() {
                chooseAreaCtrl.chooseAreaAdvancedTemplate = chooseAreaAdvancedDialogs.PICKUP_AND_DELIVERY;
                chooseAreaCtrl.currentAdvancedAreaAvailabilitySettings = Config.retailer.advancedAreaAvailabilitySettings[Config.language.id];
                var retailerSupportedDeliveryMethod = Util.getRetailerSupportedDeliveryMethod();
                if (!retailerSupportedDeliveryMethod.isPickupSupported) {
                    chooseAreaCtrl.chooseAreaAdvancedTemplate = chooseAreaAdvancedDialogs.NO_PICKUP;
                }
                chooseAreaCtrl.chooseAreaAdvancedCurrentDialog = chooseAreaCtrl.chooseAreaAdvancedTemplate;

                var PICKUP_TAB = {
                    id: 1,
                    title: chooseAreaCtrl.currentAdvancedAreaAvailabilitySettings.pickupTabName || 'Pickup',
                    templateUrl: chooseAreaAdvancedDialogs.CHOOSE_PICKUP_TAB,
                    controller: "ChooseAreaCtrl as chooseAreaCtrl",
                },
                    DELIVERY_TAB = {
                        id: 0,
                        title: chooseAreaCtrl.currentAdvancedAreaAvailabilitySettings.deliveryTabName || 'Delivery',
                        templateUrl: chooseAreaAdvancedDialogs.CHOOSE_DELIVERY_TAB,
                        controller: "ChooseAreaCtrl as chooseAreaCtrl",
                    };
                chooseAreaCtrl.tabs = [PICKUP_TAB, DELIVERY_TAB];
                chooseAreaCtrl.scenarioId = $rootScope.isChangeStore ? 1 : 0; // 0= first time, 1=returning
                var scenarios = [
                    {
                        id: 0,
                        name: 'First-Time Visitor (No address or store selected)',
                        property: 'firstTime',
                        defaultPopupTitle: 'default_first_time_popup_title',
                        defaultPopupSubTitle: 'default_first_time_popup_sub_title',
                        defaultPopupSubTitlePickup: 'default_first_time_popup_sub_title_pickup',
                        defaultPopupSubTitleDelivery: 'default_first_time_popup_sub_title_delivery',
                    },
                    {
                        id: 1,
                        name: 'Returning Visitor (Switching store selection)',
                        property: 'returning',
                        defaultPopupTitle: 'default_returning_popup_title',
                        defaultPopupSubTitle: 'default_returning_popup_sub_title',
                        defaultPopupSubTitlePickup: 'default_returning_popup_sub_title_pickup',
                        defaultPopupSubTitleDelivery: 'default_returning_popup_sub_title_delivery',
                    }
                ];
                chooseAreaCtrl.currentScenario = scenarios[chooseAreaCtrl.scenarioId];

                chooseAreaCtrl.currentSearchMessageMode = null;
               
                chooseAreaCtrl.defaultStoreIcon = 'https://d226b0iufwcjmj.cloudfront.net/global/advanced-area-availability/default-store-icon.svg';
                chooseAreaCtrl.defaultDeliveryImage = 'https://d226b0iufwcjmj.cloudfront.net/global/advanced-area-availability/default-delivery-image.png';
                chooseAreaCtrl.defaultResultsImage = 'https://d226b0iufwcjmj.cloudfront.net/global/advanced-area-availability/default-delivery-result-image.png';
                setFocusWhenCurrentDialogChanged();
            }

            function addStyle() {
                var defaultMainColor = (window.sp && window.sp.defaultThemeConfig && window.sp.defaultThemeConfig.mainColor) || '#207d3f';
                var defaultTextColor = '#ffffff';

                chooseAreaCtrl.textColor = chooseAreaCtrl.currentAdvancedAreaAvailabilitySettings.tabsAndButtonsTextColor || defaultTextColor;
                chooseAreaCtrl.bgColor = chooseAreaCtrl.currentAdvancedAreaAvailabilitySettings.tabsAndButtonsBgColor || defaultMainColor;
            }

            function getDeliveryInputPlaceholder(){
                if(chooseAreaCtrl.chooseAreaText && !!chooseAreaCtrl.chooseAreaText.placeholder.length) {
                    return chooseAreaCtrl.chooseAreaText.placeholder;
                }

                if(chooseAreaCtrl.chooseAreaMode === $rootScope.SP_SERVICES.CHOOSE_AREA_MODE.TYPE_GOOGLE_ADDRESS) {
                    return 'Please enter a delivery address';
                }

                if($rootScope.config.isZipCodeArea) {
                    return 'delivery_input_zip_code_placeholder'
                }

                return 'Please enter a city';
            }

            function setFocusWhenCurrentDialogChanged() {
                setTimeout(function () {
                    var currentDialog = document.querySelector('.manual-focus-for-accessibility');
                    if (currentDialog) {
                        currentDialog.focus();
                    }
                }, 500);
            }
            // end advanced area availability setting

            function showTab(tab) {
                chooseAreaCtrl.shownTab = tab;
                if (tab.templateUrl.includes('pickup')) {
                    filterAreasPickup();
                }
            }

            function filterFoundAreas(area) {
                return SpDeliveryAreasService.isAreaMatchTerm(area, chooseAreaCtrl.chooseAreaQuery);
            }

            /**
             * Close dialog
             * @private
             */
            function _closeDialog() {
                Dialog.hide();
            }

            /**
             * Go to filter areas state
             * @public
             */
            function goToFilterAreas() {
                chooseAreaCtrl.visibleState = chooseAreaCtrl.filterAreas;
                chooseAreaCtrl.currentSearchMessageMode = null;
                chooseAreaCtrl.chooseAreaAdvancedCurrentDialog = chooseAreaCtrl.chooseAreaAdvancedTemplate;
                setFocusWhenCurrentDialogChanged();
            }

            /**
             * Auto complete for filter areas form
             * @public
             *
             * @returns {Promise<array<string>>}
             */
            function filterAreasAutoComplete() {
                if (chooseAreaCtrl.filterAreas.addressChoices){
                    return chooseAreaCtrl.filterAreas.addressChoices;
                }
                else{
                    return SpDeliveryAreasService.autoCompleteDeliveryAreasWithFullData(chooseAreaCtrl.chooseAreaMode, chooseAreaCtrl.area).then(function(response){
                        chooseAreaCtrl.fullAutoCompleteData = response.places;
                        return response.names;
                    })
                }
            }

            /**
             * Submit filter areas form
             * @public
             *
             * @returns {undefined|Promise<array<SpDeliveryArea>>}
             */
            function filterAreasSubmit(ignoreMultipleAddresses) {
                delete chooseAreaCtrl.filterAreas.addressChoices;

                if (!(chooseAreaCtrl.area || chooseAreaCtrl.queryArea)) {
                    chooseAreaCtrl.filterAreas.enterFullAddressError = false;
                    chooseAreaCtrl.filterAreas.invalidAddressError = true;
                    return;
                }

                chooseAreaCtrl.filterAreas.invalidAddressError = false;
                var queryArea = chooseAreaCtrl.area || chooseAreaCtrl.queryArea;
                return SpDeliveryAreasService.filterDeliveryAreas(chooseAreaCtrl.chooseAreaMode,
                    queryArea,
                    ignoreMultipleAddresses,
                    _selectPlaceIdByPlaceDescription(queryArea)).then(function (response) {
                    // show invalid error message if there if no area has been found from query parameter
                    if (!response.areas.length) {
                        if (document.getElementById("delivery-image")) {
                            document.getElementById("delivery-image").style.display = "none";
                        }
                    }
                    if (!response.areas.length && chooseAreaCtrl.queryArea) {
                        chooseAreaCtrl.area = chooseAreaCtrl.queryArea;
                        chooseAreaCtrl.filterAreas.invalidAddressError = true;
                        return;
                        // show "areaNotFoundLog" if no area has been found
                    } else if (!response.areas.length && chooseAreaCtrl.area) {
                        chooseAreaCtrl.visibleState = chooseAreaCtrl.areaNotFoundLog;
                        chooseAreaCtrl.currentSearchMessageMode = searchMessageModes.NO_STORE_FOUND;
                        chooseAreaCtrl.chooseAreaAdvancedCurrentDialog = chooseAreaAdvancedDialogs.NO_STORE_FOUND;
                        setFocusWhenCurrentDialogChanged();
                        setTimeout(function(){
                            var elementEmailAddress = document.getElementById('emailAddress');
                            if (elementEmailAddress) {
                                elementEmailAddress.focus();
                            }
                        },1);
                        return;
                    }

                    var branchesIds = [];
                    chooseAreaCtrl.multiBranches = [];
                    if(response.areas.length === 1) {
                        chooseAreaCtrl.currentSearchMessageMode = searchMessageModes.ONE_STORE_FOUND;
                        chooseAreaCtrl.chooseAreaAdvancedCurrentDialog = chooseAreaAdvancedDialogs.ONE_STORE_FOUND;
                    }else {
                        chooseAreaCtrl.currentSearchMessageMode = searchMessageModes.MULTIPLE_STORE_FOUND;
                        chooseAreaCtrl.chooseAreaAdvancedCurrentDialog = chooseAreaAdvancedDialogs.MULTIPLE_STORE_FOUND;
                    }
                    setFocusWhenCurrentDialogChanged();

                    if(response.areas.length > 1) {
                        angular.forEach(response.areas, function (area) {
                            if(area.deliveryTypeId !== SP_SERVICES.DELIVERY_TYPES.PICKUP && !branchesIds.includes(area.branch.id)) {
                                branchesIds.push(area.branch.id)
                                chooseAreaCtrl.multiBranches.push(area)
                            }
                        });

                        response.areas = chooseAreaCtrl.multiBranches;
                    }

                    chooseAreaCtrl.visibleState = chooseAreaCtrl.chooseArea;
                    chooseAreaCtrl.chooseArea.isPickupButton = false;
                    chooseAreaCtrl.chooseArea.isOnlyPickupResults = !response.areas.find(function(area) {
                        return area.deliveryTypeId !== SP_SERVICES.DELIVERY_TYPES.PICKUP;
                    });
                    _setFoundAreas(response.areas, response.addressComponents);
                    $timeout(function () {
                        var continueButton = document.querySelector('#continue_shopping_btn');
                        continueButton && continueButton.focus();
                    }, 0);
                }).catch(function (err) {
                    if (err.statusCode === 400) {
                        chooseAreaCtrl.filterAreas.invalidAddressError = true;
                        return;
                    } else if (err.statusCode === 300) {
                        document.querySelector('#filter-areas-input').focus();
                        chooseAreaCtrl.area = '';
                        chooseAreaCtrl.filterAreas.addressChoices = [];
                        angular.forEach(err.data.addresses, function (address) {
                            chooseAreaCtrl.filterAreas.addressChoices.push(address.formatted_address);
                        });
                        return;
                    }

                    throw err;
                });
            }


            function _selectPlaceIdByPlaceDescription(description){
                if (chooseAreaCtrl.fullAutoCompleteData){
                    var place = chooseAreaCtrl.fullAutoCompleteData.find(function(place){ return place.description === description });
                    return place && place.place_id;
                }
                return  null;
            }

            /**
             * Reset errors state
             * @public
             */
            function filterAreasResetErrors() {
                chooseAreaCtrl.filterAreas.enterFullAddressError = false;
                chooseAreaCtrl.filterAreas.invalidAddressError = false;
            }

            /**
             * Filter areas pickup
             * @public
             */
            function filterAreasPickup() {
                chooseAreaCtrl.visibleState = chooseAreaCtrl.chooseArea;
                chooseAreaCtrl.chooseArea.isPickupButton = true;
                chooseAreaCtrl.chooseArea.isOnlyPickupResults = false;
                _setFoundAreas(chooseAreaCtrl.pickupAreas);
            }
            
            /**
             * Submit choose area form
             * @public
             *
             * @param {SpDeliveryArea} area
             *
             * @returns {Promise}
             */
            function chooseAreaSubmit(area) {
                var setCookiePromise = SpDeliveryAreasService.setRetailerIdCookie(area.retailer.id);
                var sessionUserId = user.session && user.session.userId;
                var setUserArea = user.setUserArea(area, sessionUserId);

                return Promise.all([setCookiePromise, setUserArea]).then(function () {
                    if (Config.retailer.id !== area.retailer.id) {
                        LocalStorageService.clear();
                    }

                    LocalStorageService.setItem('typedAreaText', chooseAreaCtrl.area);
                    LocalStorageService.setItem('typedAreaAddressComponents', chooseAreaCtrl.chooseArea.addressComponents);
                    Config.isAreaSelectedByUser = true;

                    return Config.changeBranch(area.branch.id, area.id, {
                        typedArea: chooseAreaCtrl.area,
                        newRetailerId: area.retailer.id
                    });
                }).then(function(isReload) {
                    Cart.init(); // Get existing cart without submitting old lines
                    $rootScope.preventCartUpdate = true;
                    if (!isReload) {
                        _closeDialog();
                    }
                })
            }

            function submitAreaNotFoundLog() {
                // TODO: validation?
                return SpDeliveryAreasService.addAreaNotFoundLog(chooseAreaCtrl.area, chooseAreaCtrl.userEmail).then(function () {
                   _closeDialog();
                });
            }

            /**
             * Set found areas
             * @private
             *
             * @param {Array<Object>} areas
             * @param {Array<Object>} [addressComponents]
             */
            function _setFoundAreas(areas, addressComponents) {
                chooseAreaCtrl.chooseArea.foundAreas = areas;
                chooseAreaCtrl.chooseArea.addressComponents = addressComponents;

                chooseAreaCtrl.chooseArea.areasDeliveryTimeTypes = {};
                SpDeliveryAreasService.getAreasDeliveryTimeTypes(chooseAreaCtrl.chooseArea.foundAreas).then(function(map) {
                    chooseAreaCtrl.chooseArea.areasDeliveryTimeTypes = map;
                    _setDeliveryWithinDaysTag();
                });
            }

            function _setDeliveryWithinDaysTag() {
                if (chooseAreaCtrl.deliveryWithinDaysTag) {
                    return;
                }

                var hasWithinDaysWarning = chooseAreaCtrl.chooseArea.foundAreas && chooseAreaCtrl.chooseArea.foundAreas.find(function(area) {
                    return _isAreaDeliveryWithinDaysOnly(area);
                });

                if (!hasWithinDaysWarning) {
                    return;
                }

                return SpProductTags.getDeliveryWithinDaysTag().then(function(tag) {
                    chooseAreaCtrl.deliveryWithinDaysTag = tag;
                });
            }

            function isShowDeliveryWithinDaysWarning(area) {
                if (!chooseAreaCtrl.deliveryWithinDaysTag) {
                    return;
                }

                return _isAreaDeliveryWithinDaysOnly(area);
            }

            function onKeyDown(event) {
                if (event.which === 13) {
                    event.stopPropagation();
                    event.stopImmediatePropagation();
                    event.preventDefault();
                    var focusElement = document.querySelector('.dialog-wrapper .dialog.choose-area-dialog .dialog-body form > .areas > div:nth-child(1) > .choose-button');
                    if (!!focusElement) {
                        focusElement.focus();
                    }

                }
            }

            function _isAreaDeliveryWithinDaysOnly(area) {
                var mapValue = chooseAreaCtrl.chooseArea.areasDeliveryTimeTypes[area.id];
                if (!mapValue) {
                    return false;
                }

                return mapValue.length === 1 && mapValue[0] === DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_DAYS;
            }

            function isBranchNameHidden() {
                if(Config.retailer.settings.isHiddenBranchName === 'true' && chooseAreaCtrl.chooseArea.foundAreas.length && chooseAreaCtrl.chooseArea.foundAreas[0].deliveryTypeId !== SP_SERVICES.DELIVERY_TYPES.PICKUP) {
                    return true;
                }

                return false;
            }
        }]);

    app.controller('PrutahTemplateChooseAreaCtrl', ['$scope', function($scope) {
        var CLASS_NAME = 'choose-area-state';

        var chooseAreaCtrl = $scope.chooseAreaCtrl;

        $scope.$watch('chooseAreaCtrl.visibleState', function() {
            var $element = angular.element(document.querySelector('.dialog-wrapper .dialog.choose-area-dialog .dialog-body'));
            if (chooseAreaCtrl.visibleState === chooseAreaCtrl.chooseArea) {
                $element.addClass(CLASS_NAME);
            } else {
                $element.removeClass(CLASS_NAME);
            }
        });
    }]);
})(angular, app);
